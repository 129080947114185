import Alert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';

const StyledAlert = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '&.MuiAlert-colorSuccess': {
    color: 'rgb(30, 70, 32)',
    backgroundColor: 'rgb(237, 247, 237)',
  },
  '&.MuiAlert-colorInfo': {
    color: 'rgb(1, 67, 97)',
    backgroundColor: 'rgb(229, 246, 253)',
  },
  '&.MuiAlert-colorWarning': {
    color: 'rgb(102, 60, 0)',
    backgroundColor: 'rgb(255, 244, 229)',
  },
  '&.MuiAlert-colorError': {
    color: 'rgb(95, 33, 32)',
    backgroundColor: 'rgb(253, 237, 237)',
  },
}));

const FormAlert: React.FC<AlertProps> = ({ ...props }) => {
  return <StyledAlert {...props} />;
};

export default FormAlert;
