import React from 'react';
import { StyledButtonMui5 } from 'components/atoms/StyledButton';
import NextLinkMui5 from 'components/atoms/NextLink';
import { useRouter } from 'next/router';
import { TopNavItemProps } from '.';
import { alpha as alphaMui5, styled as styledMui5 } from '@mui/material/styles';
import { Theme as ThemeMui5 } from '@mui/material/styles/createTheme';
import TypographyMui5 from '@mui/material/Typography';
import MenuMui5 from '@mui/material/Menu';
import MenuItemMui5 from '@mui/material/MenuItem';

type CustomLinkProps = { isActive: boolean };

const generateActiveLinkStyles = (isActive: CustomLinkProps['isActive']) =>
  isActive ? 'font-weight: 600;' : '';
const generateCommonLinkStyles = (
  theme: ThemeMui5,
  isActive: CustomLinkProps['isActive'],
) => `
  text-decoration: none;
  margin: ${theme.spacing(0, 0.25)};
  padding: ${theme.spacing(0.75, 1)};
  color: ${theme.palette.text.primary};
  cursor: pointer;
  ${generateActiveLinkStyles(isActive)}
  &:visited {
    color: ${theme.palette.text.primary};
  }
  &:hover {
    text-decoration: none;
    color: ${alphaMui5(theme.palette.common.black, 0.7)};
  }
  &.highlighted {
    border: 1px solid ${alphaMui5(theme.palette.common.black, 0.2)};
    border-radius: 3px;
  }`;

const StyledLinkMui5 = styledMui5(NextLinkMui5, {
  shouldForwardProp(propName) {
    return propName !== 'isActive';
  },
})<CustomLinkProps>(({ theme, isActive }) =>
  generateCommonLinkStyles(theme, isActive),
);

const StyledEmptyLinkMui5 = styledMui5('div', {
  shouldForwardProp(propName) {
    return propName !== 'isActive';
  },
})<CustomLinkProps>(({ theme, isActive }) =>
  generateCommonLinkStyles(theme, isActive),
);

const generateWhiteMenuButtonStyles = (theme: ThemeMui5, isWhite: boolean) => {
  if (!isWhite) return '';
  return `
    color: ${theme.palette.common.white};
    & span {
      color: ${theme.palette.common.white};
    }`;
};

const MenuButtonMui5 = styledMui5(StyledButtonMui5, {
  skipSx: false,
  shouldForwardProp(propName) {
    return propName !== 'isWhite';
  },
})<{
  isWhite: boolean;
}>(
  ({ theme, isWhite }) => `
  &.MuiButton-root {
    text-transform: none;
    max-width: 250px;
    ${theme.breakpoints.down('md')} {
      max-width: 210px;
    }
    ${generateWhiteMenuButtonStyles(theme, isWhite)}
  }`,
);

export type TopNavMenuItemProps = TopNavItemProps & {
  icon?: React.FunctionComponent;
  menuItems?: TopNavItemProps[];
};

export const TopNavMenuItem: React.FC<TopNavMenuItemProps> = ({
  icon,
  className,
  menuItems,
  children,
  isWhite,
}) => {
  const IconComponent = icon;
  const router = useRouter();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <MenuButtonMui5
        aria-controls={`${children}-menu`}
        aria-haspopup='true'
        className={className}
        isWhite={isWhite}
        variant='text'
        onClick={handleMenuOpen}
        startIcon={IconComponent ? <IconComponent /> : null}
      >
        <TypographyMui5 noWrap variant='body2' component='span'>
          {children}
        </TypographyMui5>
      </MenuButtonMui5>
      <MenuMui5
        id={`${children}-menu`}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        // transformOrigin={{
        //   vertical: -55,
        //   horizontal: 0,
        // }}
        disableScrollLock
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map((menuItem) => (
          <MenuItemMui5
            key={menuItem.name}
            className={menuItem.className}
            onClick={() => {
              if (menuItem.onClick) {
                menuItem.onClick();
                handleClose();
              }
            }}
          >
            {menuItem.href && (
              <StyledLinkMui5
                isActive={menuItem.href === router.pathname}
                href={menuItem.href}
              >
                {menuItem.name}
              </StyledLinkMui5>
            )}
            {!menuItem.href && (
              <StyledEmptyLinkMui5 isActive={menuItem.href === router.pathname}>
                {menuItem.name}
              </StyledEmptyLinkMui5>
            )}
          </MenuItemMui5>
        ))}
      </MenuMui5>
    </div>
  );
};
