import Typography from '@mui/material/Typography';
import { SelectRebates } from '../../components/organisms/SelectRebates';
import LoadingButton from '../../components/atoms/LoadingButton';
import { StyledButtonMui5 } from '../../components/atoms/StyledButton';
import { Loadable, useRecoilValue } from 'recoil';
import IconButton from '@mui/material/IconButton';
import { StyledFontAwesomeIconMui5 } from '../../components/atoms/FontAwesomeIcon';
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { UIRebate, UISelectedRebate } from '../../services/rebates/functions';
import { useScreenSize } from 'hooks/useScreenSize';
import { useRebatesCategoriesDialog } from 'components/RebatesCategoriesDialog';
import { styled, ThemeProvider } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { Theme as ThemeMui5 } from '@mui/material/styles/createTheme';
import { scanModeInputAtom } from 'containers/deal/recoil/atoms';
import { MSAPIScanMode } from 'shared/types/marketScan';
import { greyButtonLightTheme } from '../../theme';

type Props = {
  rebates: UIRebate[];
  selectedRebates: UISelectedRebate[];
  savingInProcess: boolean;
  isDealerLoadable: Loadable<boolean>;
  alignButtons?: 'left' | 'right';
  hideButtons?: boolean;
  onSave?: () => void;
  onChange?: (selectedRebates: UISelectedRebate[]) => void;
  onCancel?: () => void;
};

const Container = styled('div')(
  () => `
  display: flex;
  flex-direction: column;
  justify-content: space-between;`,
);

const QuestionsHeader = styled('div')`
  flex: 0 1 auto;
`;

const Heading = styled(Typography)(
  ({ theme }) => `
  &.MuiTypography-root {
    margin-bottom: ${theme.spacing(2)};
  }`,
);

const QuestionBody = styled('div')(
  ({ theme }) => `
  margin-top: ${theme.spacing(4)};
  flex: 1 1 auto;
  ${theme.breakpoints.down('sm')} {
    margin-top: ${theme.spacing(1)};
  }`,
);

const FormActions = styled('div', {
  shouldForwardProp: (prop) => prop !== 'alignButtons',
})<{
  alignButtons: 'left' | 'right';
}>(
  ({ theme, alignButtons }) => `
  display: flex;
  justify-content: ${alignButtons === 'left' ? 'flex-start' : 'flex-end'};
  margin-top: ${theme.spacing(2)};`,
);

const cancelButtonStyles: SxProps<ThemeMui5> = (theme) => ({
  '&.MuiButton-root': {
    marginRight: theme.spacing(2),
  },
});

export const Questions: React.FC<Props> = ({
  rebates,
  selectedRebates,
  savingInProcess,
  isDealerLoadable,
  alignButtons = 'left',
  hideButtons,
  onChange,
  onSave,
  onCancel,
}) => {
  const { isExtraSmallScreen } = useScreenSize();
  const isDealer = isDealerLoadable.valueMaybe();
  const scanMode = useRecoilValue(scanModeInputAtom);
  const isLease = scanMode === MSAPIScanMode.Lease;

  const { openRebatesCategoriesDialog } = useRebatesCategoriesDialog({
    title: 'Do you want to change answers to the questions below?',
  });

  return (
    <Container>
      <QuestionsHeader>
        {!isExtraSmallScreen && (
          <Heading variant='h5'>
            {isLease ? 'Lease ' : 'Finance '}Incentives and Discounts
          </Heading>
        )}

        <Typography variant='body2' gutterBottom>
          You can save money on your next deal by answering a couple questions
          that may qualify you for manufacturer incentives.
        </Typography>

        <Typography variant='body2'>
          Do you want to update auto-selected rebates?{' '}
          <IconButton
            onClick={() => {
              openRebatesCategoriesDialog();
            }}
          >
            <StyledFontAwesomeIconMui5
              size='2xs'
              icon={faGear}
              color='inherit'
              sx={{ color: 'inherit' }}
            />
          </IconButton>
        </Typography>
      </QuestionsHeader>

      <QuestionBody>
        <SelectRebates
          rebates={rebates}
          selectedRebates={selectedRebates}
          onChange={(selectedRebates) => onChange(selectedRebates)}
        />
      </QuestionBody>

      {!hideButtons && (
        <FormActions alignButtons={alignButtons}>
          {onCancel && (
            <ThemeProvider theme={greyButtonLightTheme}>
              <StyledButtonMui5
                color='grey'
                sx={cancelButtonStyles}
                onClick={onCancel}
              >
                Cancel
              </StyledButtonMui5>
            </ThemeProvider>
          )}
          {!isDealer && (
            <LoadingButton
              color='primary'
              loading={savingInProcess}
              onClick={onSave}
            >
              Save
            </LoadingButton>
          )}
        </FormActions>
      )}
    </Container>
  );
};
