/* eslint-disable prettier/prettier */
import { Makes } from '../config/makes';
import { GetProgramResponse } from './api/getProgram';
import {
  MSAPIAppliedRebate,
  MSAPIMake,
  MSAPIModel,
  MSISRebatesData,
  MSVehicleData,
} from './marketScan';
import { RebateCategoryAnswer } from './common';

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends Record<string, unknown>
    ? RecursivePartial<T[P]>
    : T[P];
};

export type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

export type Image = {
  '@primaryColorOptionCode'?: string;
  '@secondaryColorOptionCode'?: string;
  '@primaryRGBHexCode'?: string;
  '@secondaryRGBHexCode'?: string;
  '@width'?: '320' | '640' | '1280' | '2100' | string;
  '@height'?: '240' | '480' | '960' | '1575' | string;
  '@shotCode'?: string;
  '@backgroundDescription'?: 'White' | 'Transparent' | string;
  '@href'?: string;
  '@type'?: string;
  '@colorized'?: boolean;
  '@flags'?: {
    carryOver: 'Y' | 'N';
    exactMatch: 'Y' | 'N';
    oemTemp: 'Y' | 'N';
  };
};

export type ProcessedImage = {
  key: string;
  shot: 'front' | 'back' | 'side';
};

export type Year = number;

export type Make = {
  id?: number;
  name: Makes;
  ads_make_id: number;
  created_at?: string;
  updated_at?: string;
};

export type Model = {
  id?: number;
  ads_model_id: number;
  year: number;
  name: string;
  make_id: number;
  created_at?: string;
  updated_at?: string;
};

export type MSVehicle = {
  id?: number;
  market_scan_make_id: number;
  data: MSVehicleData;
  created_at: string;
  updated_at: string;
};

export type Trim = {
  id?: number;
  name: string;
  model_id: number;
  created_at?: string;
  updated_at?: string;
};

export type Style = {
  id?: number;
  name: string;
  model_id: number;
  ads_style_id: number;
  created_at?: string;
  updated_at?: string;
};

// ------ START OFER -----
export enum CarConditions {
  New = 'new',
  Loaner = 'loaner',
}

export type StandardOption = {
  name: string;
  header: string;
};

export type CarOption = {
  category?: string;
  name?: string;
  value?: string;
  invoiceMin?: number;
  invoiceMax?: number;
  msrpMin?: number;
  msrpMax?: number;
  details?: string[];
  oemCode?: string;
};

export type CarFeature = {
  id?: number;
  offer_id?: number;
  offer?: Model;
  type: string;
  name: string;
  image?: string;
};

export type Spec = {
  name: string;
  value: string;
};

export type FuelLowHigh = {
  low: number;
  high: number;
};

export type FuelEconomy = {
  unit: string;
  city: FuelLowHigh;
  hwy: FuelLowHigh;
};

export type Offer = {
  id?: number;
  user_id: number;
  user?: User;
  make_id: number;
  make?: Make;
  model_id: number;
  model?: Model;
  trim_id?: number;
  trim?: Trim;
  style_id?: number;
  style?: Style;
  vin: string;
  year: number;
  zip: string[];
  images: Image[];
  stock_image?: string;
  image?: string;
  color: string;
  color_code: string;
  interior: string;
  fuel_economy: FuelEconomy;
  options: CarOption[];
  standard_options: StandardOption[];
  mileage?: number;
  condition: CarConditions;
  created_at?: string;
  updated_at?: string;
  deadline?: string;
  status: OfferStatus;
  lat: number;
  lng: number;
  state: string;
  style_display_name?: string;
  vehicle_id: number;
  body_type: string;
  features?: CarFeature[];
  location: {
    type: 'Point';
    /**
     * [longitude, latitude]
     */
    coordinates: [number, number];
  };
  processed_image?: string;
  processed_images?: ProcessedImage[];
};

export enum OfferStatus {
  Active = 'active',
  DealerApproved = 'dealerApproved',
  DealerRejected = 'dealerRejected',
  VehicleDelivered = 'vehicleDelivered',
  VehicleNotDelivered = 'vehicleNotDelivered',
}

export type ModelPermissionType = {
  id?: number;
  name: string;
  label: string;
  description: string;
  created_at: string;
  updated_at: string;
};

export enum Permission {
  dealer = 'dealer',
  user = 'user',
  admin = 'admin',
  sales = 'sales',
}

export enum DataDealershipScrapeType {
  Algolia = 'algolia',
  DealerCom = 'dealercom',
  DealerON = 'dealeron',
  DealerInspire = 'dealerinspire',
}

export enum UserVerificationStatus {
  NotStarted = 'not_started',
  Started = 'started',
  Submitted = 'submitted',
  Approved = 'approved',
  ResubmissionRequested = 'resubmission_requested',
  Declined = 'declined',
  Expired = 'expired',
  Abandoned = 'abandoned',
  Unknown = 'unknown',
}

export type User = {
  id?: number;
  email: string;
  first_name?: string;
  last_name?: string;
  cognito_uuid: string;
  created_at: string;
  updated_at: string;
  zipcode: string;
  company_name?: string;
  img?: string;
  mobile_phone: string;
  tier?: string;
  income?: number;
  state: string;
  address?: string;
  permissions?: Array<{
    name:
      | Permission.admin
      | Permission.user
      | Permission.dealer
      | Permission.sales;
  }>;
  email_subscribed?: boolean;
  email_verified?: boolean;
  is_credit_tier_verified?: boolean;
  is_income_verified?: boolean;
  birth_date?: string;
  payment_customer_id?: string;
  default_payment_method_id?: string;
  payments?: Payment[];
  dti?: number;
  make?: Makes;
  rebates_names?: string[];
  rebates_categories_answers?: RebateCategoryAnswer[];
  vehicles?: UserVehicle[];
  soft_credit_check_passed_at?: string;
  market_scan_account_number?: string;
  website?: string;
  credit_score?: number;
  cosigners?: Cosigner[];
  free_delivery_range?: number;
  auto_import_inventory?: boolean;
  referral_code?: string;
};

export enum TierNames {
  SuperElite = 'Super Elite',
  Elite = 'Elite',
  Standard = 'Standard',
  StandardPlus = 'Standard Plus',
  Progressive = 'Progressive',
  Fair = 'Fair',
}

export enum TierScore {
  SuperElite = '740+',
  Elite = '700-739',
  Standard = '675-699',
  StandardPlus = '640-674',
  Progressive = '601-639',
  Fair = '0-600',
}

type TierScoreValueMapType = {
  [key in TierScore]: number;
};

export const TierScoreValueMap: TierScoreValueMapType = {
  [TierScore.SuperElite]: 750,
  [TierScore.Elite]: 720,
  [TierScore.Standard]: 690,
  [TierScore.StandardPlus]: 660,
  [TierScore.Progressive]: 620,
  [TierScore.Fair]: 600,
};

type TierScoreNameMapType = {
  [key: number]: string;
};

export const TierScoreNameMap: TierScoreNameMapType = Object.fromEntries(
  Object.entries(TierScoreValueMap).map(([key, value]) => [value, key]),
);

export const TierScoreMap = {
  [TierNames.SuperElite]: TierScore.SuperElite,
  [TierNames.Elite]: TierScore.Elite,
  [TierNames.Standard]: TierScore.Standard,
  [TierNames.StandardPlus]: TierScore.StandardPlus,
  [TierNames.Progressive]: TierScore.Progressive,
  [TierNames.Fair]: TierScore.Fair,
};

// --- AUCTION START ---- //
export enum AuctionTypes {
  Classic = 'classic',
  Dutch = 'dutch',
}

export enum AuctionStatus {
  Inactive = 'inactive',
  Active = 'active',
  Completed = 'completed',
  Canceled = 'canceled',
  Expired = 'expired',
}

export type BmwUsaMsrp = {
  id?: number;
  vin: string;
  total_msrp: any;
  created_at: string;
  updated_at: string;
};

export type BmwUsa = {
  id?: number;
  zipcode: string;
  data: any;
  created_at: string;
  updated_at: string;
};

export type EmailSubscription = {
  id?: number;
  email: string;
  created_at: string;
  updated_at: string;
};

export type UserPermission = {
  id?: number;
  user_id: number;
  user?: User;
  permission_id: number;
  permission?: ModelPermissionType;
  created_at: string;
  updated_at: string;
};

export type AuctionPresence = {
  id?: number;
  user_id?: number;
  user?: User;
  auction_id: number;
  auction?: Auction;
  created_at: string;
};

export type AuctionVisit = {
  id?: number;
  user_id?: number;
  user?: User;
  unathorised_user_id?: string;
  auction_id: number;
  auction?: Auction;
  created_at: string;
  updated_at: string;
};

export type AuctionLead = {
  id?: number;
  user_id?: number;
  user?: User;
  auction_id: number;
  auction?: Auction;
  bid_id?: number;
  bid?: Bid;
  slack_thread_ts?: string;
  created_at: string;
  updated_at: string;
};

export type Auction = {
  id?: number;
  offer_id: number;
  offer?: Offer;
  type: AuctionTypes;
  status: AuctionStatus;
  msrp: number;
  invoice_price: number;
  selling_price: number;
  discount: number;
  start_selling_price?: number;
  start_discount?: number;
  start_time: string;
  end_time: string;
  rebates_data: MSISRebatesData;
  auto_relist: boolean;
  bids?: Bid[];
  payments?: Payment[];
  watchers?: Watcher[];
  visits?: Visit[];
  buy_it_now_discount?: number;
  created_at: string;
  updated_at: string;
  is_default_configuration?: boolean;
  bezier_params?: number[];
  is_featured?: boolean;
};

export type AuctionMeta = {
  availableToRelist?: boolean;
};

// --- AUCTION END ---- //

export enum BidStatus {
  Active = 'active',
  DealerApproved = 'dealerApproved',
  DealerRejected = 'dealerRejected',
  UserConfirmationNeeded = 'userConfirmationNeeded',
  CosignerNeeded = 'cosignerNeeded',
  UserAccepted = 'userAccepted',
  UserDeclined = 'userDeclined',
  UserSupportNeeded = 'userSupportNeeded',
  UserReceivedVehicle = 'userReceivedVehicle',
  UserNotReceivedVehicle = 'userNotReceivedVehicle',
  Archived = 'archived',
}

export type AuctionBid = {
  id: number;
  status: BidStatus;
  user_id: number;
  is_auto_bid: boolean;
  min_program_payment: string;
  max_program_payment: string;
  min_program_price: number;
  max_program_price: number;
  created_at: string;
  'user.email': string;
  'user.img': string;
};

export type UserBid = {
  id?: number;
  status: BidStatus;
  user_id: number;
  status_updated_at?: string;
  min_program_payment?: string;
  max_program_payment?: string;
  min_program_price?: number;
  max_program_price?: number;
  min_program?: GetProgramResponse;
  max_program?: GetProgramResponse;
};

export type WinningBid = {
  id: number;
  status: BidStatus;
  user_id: number;
  min_program_payment: string;
  max_program_payment: string;
  min_program_price: number;
  max_program_price: number;
  min_program?: GetProgramResponse;
  max_program?: GetProgramResponse;
  potential_incentives?: MSAPIAppliedRebate[];
  'user.credit_score': number;
};

export type Bid = {
  id?: number;
  user_id: number;
  user?: User;
  auction_id: number;
  auction?: Auction;
  is_auto_bid?: boolean;
  status: BidStatus;
  status_updated_at?: string;
  rejection_reason?: string;
  max_program: GetProgramResponse;
  min_program: GetProgramResponse;
  potential_incentives?: MSAPIAppliedRebate[];
  created_at: string;
  updated_at: string;
};

export type Watcher = {
  id?: number;
  user_id: number;
  user?: User;
  auction_id: number;
  auction?: Auction;
  created_at: string;
  updated_at: string;
};

export type Visit = {
  id?: number;
  user_id: number;
  auction_id: number;
  auction: Auction;
};

export type Log = {
  id?: number;
  name: LogName;
  data?: Record<string, unknown>;
  created_at?: string | Date;
  updated_at?: string | Date;
};

export enum LogName {
  requestDescribeVehicleAsync = 'requestDescribeVehicleAsync',
  updateDescribeVehiclsAsync = 'updateDescribeVehiclsAsync',
}

export type Cosigner = {
  id?: number;
  user_id: number;
  user?: User;
  email: string;
  first_name: string;
  last_name: string;
  birth_date: string;
  tier: string;
  income: number;
  credit_score?: number;
  is_credit_tier_verified?: boolean;
  is_income_verified?: boolean;
  address?: string;
  zipcode?: string;
  request_status?: CosignerRequestStatus;
  request_uuid?: string;
  created_at: string;
  updated_at: string;
};

export enum CosignerRequestStatus {
  Pending = 'pending',
  CosignerAccepted = 'cosignerAccepted',
  CosignerDeclined = 'cosignerRejected',
  UserCanceled = 'userCanceled',
}

export type Payment = {
  id?: number;
  user_id: number;
  user?: User;
  auction_id: number;
  amount?: number;
  amount_captured?: number;
  status: string;
  error_message?: string;
  error_code?: string;
  payment_method: string;
  payment_intent: string;
  created_at?: string;
  updated_at?: string;
};

export enum PaymentStatus {
  Canceled = 'canceled',
  Processing = 'processing',
  RequiresAction = 'requires_action',
  RequiresCapture = 'requires_capture',
  RequiresConfirmation = 'requires_confirmation',
  RequiresPaymentMethod = 'requires_payment_method',
  Succeeded = 'succeeded',
  Refunded = 'refunded',
}

export type Question = {
  id?: number;
  market_scan_make_id?: number;
  market_scan_make?: MSMake;
  rebates?: Rebate[];
  text: string;
  description?: string;
  created_at?: string;
  updated_at?: string;
};

export type Rebate = {
  id?: number;
  name: string;
  category: string;
  description: string;
  created_at?: string;
  updated_at?: string;
};

export type UserRebate = {
  rebate_name: string;
  rebate?: Rebate;
  user_id: number;
  user?: User;
  created_at: string;
  updated_at: string;
};

export type RebateQuestion = {
  id?: number;
  question_id: number;
  question?: Question;
  rebate_name: string;
  rebate?: Rebate;
  created_at: string;
  updated_at: string;
};

export type RebateZipcodeScrape = {
  id?: number;
  zipcode: string;
  state?: string;
  // if we want to bind zipcode to a specific dealer
  dealer_name?: string;
  dealer_make?: string;
  created_at?: string;
  updated_at?: string;
};

export type Zipcode = {
  zipcode: string;
  city: string;
  state: string;
  latitude: string;
  longitude: string;
  created_at: string;
  updated_at: string;
  location: {
    type: 'Point';
    /**
     * [longitude, latitude]
     */
    coordinates: [number, number];
  };
};

export type Migration = {
  name: string;
  status: string;
};

export type Inventory = {
  id?: number;
  zipcode: string;
  make: string;
  dealer: string;
  data: InventoryItemRaw[];
  created_at: string;
  updated_at: string;
};

export type InventoryItemRaw = {
  id?: number;
  dealser: string;
  zipcode: string;
  vin: string;
  title: string;
  make: string;
  model: string;
  year: string;
  package_code: string;
  image: string;
  msrp: number;
  exterior_color: string;
  interior_color: string;
  status: 'new' | 'demo';
  mileage: number;
  created_at: string;
  updated_at: string;
  features: string[];
};

export type Verification = {
  id?: number;
  user_id?: number;
  user?: User;
  cosigner_id?: number;
  cosigner?: Cosigner;
  session_url?: string;
  session_id?: string;
  status?: UserVerificationStatus;
  created_at: string;
  updated_at: string;
};

export type MSMake = {
  id?: number;
  market_scan_make_id: number;
  data: MSAPIMake;
  created_at?: string;
  updated_at?: string;
};

export type MSModel = {
  id?: number;
  market_scan_make_id: number;
  market_scan_model_id: number;
  data: MSAPIModel;
  created_at?: string;
  updated_at?: string;
};

export type UserVehicle = {
  id?: number;
  user_id?: number;
  year?: number;
  market_scan_make_id?: number;
  market_scan_model_id?: number;
  created_at?: string;
  updated_at?: string;
};

export type DataDealership = {
  id?: number;
  make?: string;
  name?: string;
  street?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  phone?: string;
  email?: string;
  website?: string;
  created_at?: string;
  updated_at?: string;
  scraped_at?: string;
  scrape_type?: string;
  scrape_enabled?: boolean;
  scrape_settings?: Record<string, unknown>;
};

export type UserReferral = {
  id?: number;
  referrer_id: number;
  referrer?: User;
  referral_id: number;
  referral?: User;
  created_at: string;
  updated_at: string;
};
