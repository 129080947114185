import React, { ElementType } from 'react';
import { styled, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { StyledFontAwesomeIconMui5 } from 'components/atoms/FontAwesomeIcon';
import { TrustBadges } from 'components/organisms/TrustBadges';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import Typography from '@mui/material/Typography';
import { FooterItem, FooterItemProps } from 'components/atoms/Footer';
import { NextLink } from 'components/atoms/NextLink';
import Image from 'next/image';
import { Url } from 'next/dist/shared/lib/router/router';
import { darkThemeMui5 } from '../../theme';
import {
  BRAND_NAME,
  DOMAIN_NAME,
  SUPPORT_EMAIL,
  facebookUrl,
  instagramUrl,
  linkedinUrl,
  twitterUrl,
} from 'shared/utils/constants';
// import { Stats } from 'shared/types/endpoints';
// import Rest from 'services/rest';

const Wrapper = styled(Paper)(({ theme }) => ({
  '&.MuiPaper-root': {
    overflow: 'hidden',
    borderTop: `1px solid ${theme.palette.divider}`,
    background: theme.palette.background.default,
    paddingBottom: theme.spacing(3),
  },
}));

const TopRow = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[400]}`,
  marginBottom: theme.spacing(1),
}));

const VerticalDivider = styled('div')(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.grey[400]}`,
  margin: theme.spacing(0, 3),
  height: theme.spacing(2),
}));

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(3),
}));

const LogoContent = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const LogoContainer = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(3, 0, 3, 3),
  borderBottom: 'none',
  [theme.breakpoints.down('sm')]: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
}));

const Title = styled(Typography)(() => ({
  '&.MuiTypography-root': {
    fontSize: '1.25rem',
    fontWeight: 500,
    display: 'block',
  },
}));

// const IconCircle = styled('div')(({ theme }) => ({
//   width: 40,
//   height: 40,
//   marginRight: theme.spacing(2),
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   borderRadius: '50%',
//   background: theme.palette.common.white,
// }));

const StyledIconButton = styled(IconButton)<{
  component?: ElementType;
  href: Url;
}>(({ theme }) => ({
  '&.MuiIconButton-root': {
    width: 40,
    height: 40,
    background: theme.palette.secondary.main,
    margin: theme.spacing(3, 2, 0, 0),
  },
}));

export type FooterLink = {
  name: string;
} & FooterItemProps;

type Props = {
  links: FooterLink[];
  bottomLinks: FooterLink[];
};

// const rest = new Rest();

export default function Footer({
  links = [],
  bottomLinks = [],
}: Props): JSX.Element {
  // Uncomment when you decide to revert footer stats

  // const [websiteStats, setWebsiteStats] = useState<Stats>();
  // const { totalBids, totalAuctions } = websiteStats || {};

  // useEffect(() => {
  //   rest.getWebsiteStats().then(setWebsiteStats);
  // }, []);

  return (
    <ThemeProvider theme={darkThemeMui5}>
      <Wrapper className='js-footer' elevation={0} square>
        <TopRow>
          <Container maxWidth='lg' disableGutters>
            <LogoContent>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={3} md={3}>
                  <LogoContainer>
                    <Image
                      alt={`${BRAND_NAME} logo`}
                      width='220'
                      height='16'
                      src={`/img/${DOMAIN_NAME.replace(
                        '.com',
                        '',
                      )}_logo_white.svg`}
                    />
                  </LogoContainer>
                </Grid>
                <Grid item xs={12} sm={9} md={9}>
                  <TrustBadges />
                </Grid>
                {/* {totalBids > 0 && (
                  <Grid item xs={12} sm={6} md={3}>
                    <Box display='flex' alignItems='center'>
                      <IconCircle>
                        <FontAwesomeIcon
                          icon={faGavel}
                          color={darkTheme.palette.primary.main}
                        />
                      </IconCircle>
                      <div>
                        <Typography variant='h6'>{totalBids}+</Typography>
                        <Typography variant='body2'>Bids</Typography>
                      </div>
                    </Box>
                  </Grid>
                )}
                {totalAuctions > 0 && (
                  <Grid item xs={12} sm={6} md={3}>
                    <Box display='flex' alignItems='center'>
                      <IconCircle>
                        <FontAwesomeIcon
                          icon={faCar}
                          color={darkTheme.palette.primary.main}
                        />
                      </IconCircle>
                      <div>
                        <Typography variant='h6'>{totalAuctions}+</Typography>
                        <Typography variant='body2'>Auctions</Typography>
                      </div>
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={3}>
                  <Box display='flex' alignItems='center'>
                    <IconCircle>
                      <FontAwesomeIcon
                        icon={faMapSigns}
                        color={darkTheme.palette.primary.main}
                      />
                    </IconCircle>
                    <div>
                      <Typography variant='h6'>3</Typography>
                      <Typography variant='body2'>States</Typography>
                    </div>
                  </Box>
                </Grid> */}
              </Grid>
            </LogoContent>
          </Container>
        </TopRow>
        <Container maxWidth='lg' disableGutters>
          <Content>
            <Grid container spacing={10}>
              <Grid item xs={12} sm={6} md={4}>
                <Title variant='caption' gutterBottom>
                  About {BRAND_NAME}
                </Title>
                <Typography variant='body2' gutterBottom>
                  {BRAND_NAME} is a leading online car leasing and financing
                  company dedicated to simplifying the process of obtaining your
                  next vehicle. Our mission is to offer the best car leasing and
                  financing options in NY, NJ, PA, CT, MD and CA, making both
                  luxury car leasing and affordable car financing accessible to
                  the average consumer.
                </Typography>
                <StyledIconButton
                  aria-label={`${BRAND_NAME} on Facebook`}
                  component={NextLink}
                  size='small'
                  href={facebookUrl}
                >
                  <StyledFontAwesomeIconMui5
                    icon={faFacebookF}
                    color='inherit'
                    sx={{ color: 'inherit' }}
                  />
                </StyledIconButton>
                <StyledIconButton
                  aria-label={`${BRAND_NAME} on Twitter`}
                  component={NextLink}
                  size='small'
                  href={twitterUrl}
                >
                  <StyledFontAwesomeIconMui5
                    icon={faTwitter}
                    color='inherit'
                    sx={{ color: 'inherit' }}
                  />
                </StyledIconButton>
                <StyledIconButton
                  aria-label={`${BRAND_NAME} on Instagram`}
                  component={NextLink}
                  size='small'
                  href={instagramUrl}
                >
                  <StyledFontAwesomeIconMui5
                    icon={faInstagram}
                    color='inherit'
                    sx={{ color: 'inherit' }}
                  />
                </StyledIconButton>
                <StyledIconButton
                  aria-label={`${BRAND_NAME} on Linkedin`}
                  component={NextLink}
                  size='small'
                  href={linkedinUrl}
                >
                  <StyledFontAwesomeIconMui5
                    icon={faLinkedinIn}
                    color='inherit'
                    sx={{ color: 'inherit' }}
                  />
                </StyledIconButton>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Title variant='caption' gutterBottom>
                  Useful Links
                </Title>
                {links.map(({ name, ...props }) => (
                  <FooterItem key={name} size='small' {...props}>
                    {name}
                  </FooterItem>
                ))}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Title variant='caption' gutterBottom>
                  Get in touch
                </Title>
                <FooterItem
                  size='small'
                  startIcon={
                    <StyledFontAwesomeIconMui5
                      icon={faEnvelope}
                      color='inherit'
                      sx={{ color: 'inherit' }}
                    />
                  }
                  href={`mailto:support@${DOMAIN_NAME}`}
                >
                  {SUPPORT_EMAIL}
                </FooterItem>
                <FooterItem
                  size='small'
                  startIcon={
                    <StyledFontAwesomeIconMui5
                      icon={faPhone}
                      color='inherit'
                      sx={{ color: 'inherit' }}
                    />
                  }
                  href='tel:5122737775'
                >
                  512-273-7775
                </FooterItem>
                <FooterItem
                  size='small'
                  startIcon={
                    <StyledFontAwesomeIconMui5
                      icon={faMapMarkerAlt}
                      color='inherit'
                      sx={{ color: 'inherit' }}
                    />
                  }
                  href='https://goo.gl/maps/XycZtP4aTRYKbfKg7'
                >
                  13809 Research Boulevard, Suite 500
                  <br />
                  Austin TX 78750
                </FooterItem>
              </Grid>
            </Grid>
          </Content>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexWrap='wrap'
          >
            <FooterItem size='small'>
              ©&nbsp;{BRAND_NAME}&nbsp;{new Date().getFullYear()}
            </FooterItem>
            {bottomLinks.map((link) => (
              <React.Fragment key={link.name}>
                <VerticalDivider />
                <FooterItem size='small' href={link.href}>
                  {link.name}
                </FooterItem>
              </React.Fragment>
            ))}
          </Box>
        </Container>
      </Wrapper>
    </ThemeProvider>
  );
}
