import React, { ElementType } from 'react';
import { NextLink } from 'components/atoms/NextLink';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const LinkButton = styled(Button)<{ component?: ElementType }>(() => ({
  '&.MuiButton-root': {
    textTransform: 'none',
    fontWeight: 'normal',
    justifyContent: 'left',
    color: 'inherit',
  },
}));

export type FooterItemProps = ButtonProps;

export const FooterItem: React.FC<FooterItemProps> = (props) => {
  return (
    <div>
      <LinkButton
        {...props}
        component={props.href ? NextLink : 'button'}
        className={`${props.className}`}
      />
    </div>
  );
};
