import { Makes } from '../config/makes';
import { CreateAuctionFormValues } from './createAuctionFormValues';
import {
  Auction,
  AuctionMeta,
  CarConditions,
  CarOption,
  CarFeature,
  Image,
  Offer,
  StandardOption,
  TierNames,
  Permission,
  FuelEconomy,
  ProcessedImage,
} from './models';

export type RequestVehicle = {
  email: string;
  zipcode: string;
  make?: string;
};

export interface CreateUser {
  email: string;
  first_name?: string;
  last_name?: string;
  company_name?: string;
  zipcode: string;
  state: string;
  make?: Makes;
  address?: string;
  website?: string;
  mobile_phone: string;
  tier?: TierNames;
  income?: number;
  password: string;
  passwordConfirm: string;
  permissions: Permission[];
  birth_date?: string;
  is_credit_tier_verified?: boolean;
  is_income_verified?: boolean;
  cosigner_email?: string;
  cosigner_first_name?: string;
  cosigner_last_name?: string;
  cosigner_tier?: string;
  cosigner_income?: number;
  cosigner_credit_score?: number;
  cosigner_birth_date?: string;
  cosigner_is_credit_tier_verified?: boolean;
  cosigner_is_income_verified?: boolean;
  cosigner_address?: string;
  cosigner_zipcode?: string;
  free_delivery_range?: number;
  auto_import_inventory?: boolean;
}

export interface CreateAuctionsOfferType
  extends Omit<
    Offer,
    | 'id'
    | 'user_id'
    | 'user'
    | 'make_id'
    | 'make'
    | 'model_id'
    | 'model'
    | 'image'
    | 'trim_id'
    | 'trim'
    | 'style_id'
    | 'trim'
    | 'state'
    | 'lat'
    | 'lng'
    | 'location'
    | 'created_at'
    | 'updated_at'
    | 'vehicle_id'
  > {
  makeName: Makes;
  modelName: string;
  trimName: string;
  styleName: string;
  styleId: number;
}

export type CreateAuctionsAuctionType = Omit<
  Auction,
  | 'id'
  | 'offer_id'
  | 'offer'
  | 'rebates_data'
  | 'created_at'
  | 'updated_at'
  | 'auto_relist'
>;

export type CreateAuctionsFeaturesType = Omit<
  CarFeature,
  'id' | 'offer_id' | 'offer'
>;

export type CreateAuctions = {
  data: Array<{
    auction: CreateAuctionsAuctionType;
    offer: CreateAuctionsOfferType;
  }>;
};

export type UpdateAuction = {
  auction: Omit<
    Auction,
    | 'id'
    | 'offer_id'
    | 'offer'
    | 'created_at'
    | 'updated_at'
    | 'status'
    | 'msrp'
    | 'invoice_price'
    | 'incentives'
    | 'rebates_data'
    | 'is_default_configuration'
    | 'auto_relist'
  >;
  offer: {
    mileage: number;
    condition: CarConditions;
  };
};

export type BulkVinData = {
  vin: string;
  error?: string;
  vehicle?: VinData;
  initialFormValues?: CreateAuctionFormValues;
};

export interface VinData {
  vin: string;
  makeName: Makes;
  modelName: string;
  trimName: string;
  styleName: string;
  styleId: number;
  styleDisplayName: string;
  interiorColor: string;
  exteriorColor: string;
  exteriorColorCode: string;
  year: number;
  msrp: number;
  invoicePrice: number;
  images: Image[];
  processedImages: ProcessedImage[];
  stockImage: string;
  options: CarOption[];
  fuelEconomy: FuelEconomy;
  standardOptions: StandardOption[];
  zipcode?: string;
  features?: CarFeature[];
  bodyType: string;
}

export enum SortOrder {
  Desc = 'desc',
  Asc = 'asc',
}

export enum SortField {
  Payment = 'Payment',
  EndTime = 'end_time',
  Created = 'created_at',
  BestDeal = 'best_deal',
  ID = 'id',
  Random = 'random',
}

export enum SortType {
  Completed = 'completed',
  EndingSoonest = 'endingsoonest',
  Newest = 'newest',
  PriceHighLow = 'pricehighlow',
  PriceLowHigh = 'pricelowhigh',
  BestDeal = 'best_deal',
  ExactMatch = 'exact_match',
  Random = 'random',
}

export type GetAuctionInitialValues = {
  makeName: string;
  modelName: string;
  styleName: string;
};

export enum GetAuctionStatus {
  Active = 'active',
  Past = 'past',
}

export type DealerVehicle = {
  vin: string;
  name: string;
  model: string;
  make: string;
  year: number;
  image: string;
  msrp: number;
  invoicePrice: number;
  interiorColor: string;
  interiorImg?: string;
  exteriorColor: string;
  convertedExteriorColor?: string;
  exteriorImg?: string;
  status: CarConditions;
  mileage: number;
  listed?: boolean;
  packageCode?: string;
};

export type Stats = {
  totalBids: number;
  totalAuctions: number;
};

export type AuthCredentials = {
  email: string;
  password: string;
};

export type GetAuctionResponse = {
  auction: Auction;
  metadata: AuctionMeta;
};

export enum TabTypes {
  classic = 'classic',
  dutch = 'dutch',
  canceled = 'canceled',
  expired = 'expired',
  completed = 'completed',
}

export enum DealTypeEnum {
  LEASE = 'lease',
  FINANCE = 'finance',
}
