import {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Questions } from './Questions';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import { LeaseCarSavings } from '../../components/organisms/LeaseCarSavings';
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
} from 'recoil';
import { auctionAtom, scanModeInputAtom } from '../deal/recoil/atoms';
import {
  getBestDealsRequestParams,
  PLACEHOLDER_BACKGROUND,
  PLACEHOLDER_FOREGROUND,
} from '../../data/constants';
import { Auction } from '../../shared/types/models';
import Rest from '../../services/rest';
import { rebatesCategoriesAtom, userAtom } from '../../recoil/atoms';
import { rebatesToUiRebates } from '../../hooks/useAuctionUserRebates';
import {
  bidFormProgramSelector,
  buyItNowSellingPriceSelector,
  currentSellingPriceSelector,
  isDealerSelector,
} from 'containers/deal/recoil/selectors';
import omit from 'lodash/omit';
import { getAuctionAppliedRebates } from 'shared/incentives/getAuctionAppliedRebates';
import {
  DEALER_REBATE,
  getAutoSelectedRebates,
  getDealerRebate,
  SELECTED_REBATE_NAME_FIELD,
  UISelectedRebate,
} from '../../services/rebates/functions';
import { useScreenSize } from 'hooks/useScreenSize';
import {
  auctionRebatesSelector,
  userAuctionRebatesSelector,
} from 'recoil/selectors';
import { updateUserRebatesCallback } from 'recoil/utils/updateUserRebatesCallback';
import ContentLoader from 'react-content-loader';
import { MSAPIScanMode } from 'shared/types/marketScan';
import { styled } from '@mui/material/styles';

type Props = {
  zipcode?: string;
  divider?: boolean;
  bestDeal?: boolean;
  alignButtons?: 'left' | 'right';
  hideButtons?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
};

export interface IIncentivesData {
  save(): void;
}

const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isCompact' && prop !== 'divider',
})<{
  isCompact?: boolean;
  divider?: boolean;
}>(
  ({ theme, isCompact, divider }) => `
  display: grid;
  grid-template-columns: ${divider ? '1fr 1px 1fr' : '1fr 1fr'};
  gap: ${theme.spacing(isCompact ? 3 : divider ? 3 : 10)};
  ${theme.breakpoints.down('md')} {
    display: block;
  }`,
);

const rest = new Rest();

const getBestDeal = async (zipcode: string): Promise<Auction> => {
  const response = await rest.getAuctions(
    getBestDealsRequestParams(zipcode, 1),
  );
  return response.count > 0 ? response.auctions[0] : null;
};

const useAuctionUserRebates = (
  auction: Auction,
): [
  UISelectedRebate[],
  (selectedRebates: UISelectedRebate[]) => Promise<void>,
] => {
  const userAuctionRebates =
    useRecoilValue(userAuctionRebatesSelector(auction)) || [];

  const updateUserRebates = useRecoilCallback(updateUserRebatesCallback);

  const saveRebates = (selectedRebates: UISelectedRebate[]) => {
    const rebatesNamesToRemove = userAuctionRebates
      .filter((userAuctionRebate) => {
        const stillInList = selectedRebates.some((selectedRebate) => {
          return (
            selectedRebate[SELECTED_REBATE_NAME_FIELD] ===
            userAuctionRebate.Name
          );
        });

        return !stillInList;
      })
      .map((rebate) => rebate.Name);

    const rebatesNamesToAdd = selectedRebates
      .filter((selectedRebate) => {
        return !userAuctionRebates.some(
          (userAuctionRebate) =>
            userAuctionRebate.Name ===
            selectedRebate[SELECTED_REBATE_NAME_FIELD],
        );
      })
      .map((selectedRebate) => selectedRebate[SELECTED_REBATE_NAME_FIELD]);

    return updateUserRebates(rebatesNamesToRemove, rebatesNamesToAdd);
  };

  const selectedRebates = userAuctionRebates.map((userRebate) => {
    return {
      [SELECTED_REBATE_NAME_FIELD]: userRebate.Name,
    };
  });

  return [selectedRebates, saveRebates];
};

export const IncentivesDataLoadingPlaceholder = () => {
  return (
    <ContentLoader
      speed={2}
      width={342}
      height={480}
      viewBox='0 0 342 480'
      backgroundColor={PLACEHOLDER_BACKGROUND}
      foregroundColor={PLACEHOLDER_FOREGROUND}
    >
      <rect x='0' y='0' rx='3' ry='3' width='342' height='60' />
      <rect x='0' y='70' rx='3' ry='3' width='250' height='20' />
      <rect x='0' y='100' rx='3' ry='3' width='100' height='20' />
      <rect x='0' y='130' rx='3' ry='3' width='140' height='20' />
      <rect x='0' y='160' rx='3' ry='3' width='180' height='20' />

      <rect x='0' y='200' rx='0' ry='0' width='342' height='1' />

      <rect x='0' y='220' rx='3' ry='3' width='342' height='80' />
      <rect x='0' y='310' rx='3' ry='3' width='342' height='80' />
      <rect x='0' y='400' rx='3' ry='3' width='342' height='80' />
    </ContentLoader>
  );
};

const IncentivesData: ForwardRefRenderFunction<IIncentivesData, Props> = (
  {
    zipcode,
    divider,
    bestDeal,
    alignButtons = 'left',
    hideButtons,
    onCancel,
    onSave,
  },
  ref,
) => {
  const { isExtraSmallScreen } = useScreenSize();
  const [auction, setAuction] = useRecoilState(auctionAtom);
  const [loading, setLoading] = useState(bestDeal);

  const user = useRecoilValue(userAtom);
  const [isSaving, setIsSaving] = useState(false);

  const auctionRebates = useRecoilValue(
    auctionRebatesSelector({
      auction,
      overwrite: {
        zipcode,
      },
    }),
  );

  const [selectedRebates, saveSelectedRebates] = useAuctionUserRebates(auction);

  const getDefaultSelectedRebates = () => {
    return selectedRebates.length > 0
      ? [...selectedRebates, DEALER_REBATE]
      : [DEALER_REBATE];
  };

  const [localSelectedRebates, setLocalSelectedRebates] = useState<
    UISelectedRebate[]
  >(getDefaultSelectedRebates());

  const selectedMsisRebates = auctionRebates.filter((rebate) =>
    [...localSelectedRebates].some(
      (uiRebate) => uiRebate[SELECTED_REBATE_NAME_FIELD] === rebate.Name,
    ),
  );

  const currentSellingPrice = useRecoilValueLoadable(
    currentSellingPriceSelector,
  ).valueMaybe();

  const buyItNowSellingPrice = useRecoilValueLoadable(
    buyItNowSellingPriceSelector,
  ).valueMaybe();

  const salesPrice = currentSellingPrice || buyItNowSellingPrice;

  const program = useRecoilValueLoadable(
    bidFormProgramSelector({
      salesPrice,
      selectedRebates: selectedMsisRebates,
      zipcode,
    }),
  );

  const appliedRebates = getAuctionAppliedRebates({
    auctionRebates,
    program: program.valueMaybe(),
  });

  const autoSelectedRebatesCategories = useRecoilValueLoadable(
    rebatesCategoriesAtom,
  );
  const autoSelectedRebates = getAutoSelectedRebates(
    auctionRebates,
    autoSelectedRebatesCategories.valueMaybe(),
  );

  const scanMode = useRecoilValue(scanModeInputAtom);
  const isLease = scanMode === MSAPIScanMode.Lease;

  const rebates = useMemo(() => {
    const rebates = rebatesToUiRebates({
      autoSelectedRebates,
      auctionMsisRebates: auctionRebates,
      appliedRebates,
      selectedMsisRebates,
      // rebatesQuestions,
      loading: program.state === 'loading',
      msrp: auction?.msrp,
      isLease,
    });
    const dealerRebate = getDealerRebate(auction.msrp, salesPrice);
    if (dealerRebate.value !== 0) {
      rebates.unshift(dealerRebate);
    }

    return rebates;
  }, [
    autoSelectedRebates,
    auctionRebates,
    appliedRebates,
    selectedMsisRebates,
    // rebatesQuestions,
    program.state,
    auction?.msrp,
    salesPrice,
  ]);

  const rebatesHash = JSON.stringify(
    rebates.map((rebate) => omit(rebate, 'status')),
  );

  const isDealerLoadable = useRecoilValueLoadable(isDealerSelector);

  useEffect(() => {
    setLocalSelectedRebates(getDefaultSelectedRebates());
  }, [rebatesHash]);

  const handleSave = () => {
    setIsSaving(true);
    saveSelectedRebates(localSelectedRebates).then(() => {
      setIsSaving(false);
      onSave?.();
    });
  };

  useImperativeHandle(ref, () => ({
    save: () => handleSave(),
  }));

  useEffect(() => {
    if (bestDeal) {
      getBestDeal(user?.zipcode).then((bestDealAuction) => {
        setAuction(bestDealAuction || null);
        setLoading(false);
      });
    }
  }, [bestDeal, setAuction, user?.zipcode]);

  return (
    <Container isCompact={isExtraSmallScreen} divider={divider}>
      <Questions
        rebates={rebates}
        selectedRebates={localSelectedRebates}
        savingInProcess={isSaving}
        alignButtons={alignButtons}
        isDealerLoadable={isDealerLoadable}
        hideButtons={hideButtons}
        onSave={handleSave}
        onChange={(inputRebates) => setLocalSelectedRebates(inputRebates)}
        onCancel={onCancel}
      />
      {!isExtraSmallScreen && divider && <Divider orientation={'vertical'} />}
      {loading ? (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress size={50} />
        </Box>
      ) : (
        <>
          <LeaseCarSavings
            auction={auction}
            selectedRebatesText={
              rebates.length === localSelectedRebates.length
                ? 'all'
                : 'selected'
            }
            selectedRebates={selectedMsisRebates}
          />
        </>
      )}
    </Container>
  );
};

export default forwardRef(IncentivesData);
