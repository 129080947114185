import {
  RebateCategoryAnswer,
  RebateCategoryQuestion,
} from '../../shared/types/common';
import {
  MSAPIRequestRebateCategory,
  MSISRebate,
} from '../../shared/types/marketScan';
import isArray from 'lodash/isArray';
import { getRebateValue } from '../../shared/incentives/getRebateValue';
import { IncentiveStatus } from '../../shared/incentives';
import { BRAND_NAME } from 'shared/utils/constants';

export const getAutoSelectedRebates = (
  auctionRebates: MSISRebate[],
  autoSelectedRebatesCategories: MSAPIRequestRebateCategory[] = [],
) => {
  if (!isArray(auctionRebates)) {
    return [];
  }

  const autoSelectedRebates = auctionRebates.filter(
    (auctionRebate) =>
      auctionRebate.Selected ||
      autoSelectedRebatesCategories.some((category) => {
        const sameCategory = category.ID === auctionRebate.CategoryID;
        const sameSubcategory =
          auctionRebate.SubcategoryID && category.Subcategory
            ? category.Subcategory.some(
                (subcategoryID) =>
                  subcategoryID === auctionRebate.SubcategoryID,
              )
            : true;

        return sameCategory && sameSubcategory;
      }),
  );

  return autoSelectedRebates;
};

export const evaluateRelevantRebatesCategoriesAnswers = (
  rebatesCategoriesQuestions: RebateCategoryQuestion[],
  deprecatedAnswers: RebateCategoryAnswer[],
): RebateCategoryAnswer[] =>
  rebatesCategoriesQuestions.map((question) => {
    const exactAnswer = deprecatedAnswers.find(
      (answer) => answer.title === question.title,
    );
    return exactAnswer ?? { title: question.title, isApplied: false };
  });

export const getAllRebatesTotal = (
  rebates: MSISRebate[],
  msrp: number,
  sellingPrice: number,
) => {
  // noinspection UnnecessaryLocalVariableJS
  const total = rebates.reduce((sum, rebate) => {
    return sum + getRebateValue(rebate, msrp);
  }, getDealerRebate(msrp, sellingPrice).value);

  return total;
};

export const SELECTED_REBATE_NAME_FIELD = 'id';
export type UISelectedRebate = {
  [SELECTED_REBATE_NAME_FIELD]: string;
};

const DEALER_REBATE_ID = 'dealer-rebate';
export const DEALER_REBATE: UISelectedRebate = {
  [SELECTED_REBATE_NAME_FIELD]: DEALER_REBATE_ID,
};

export type UIRebate = UISelectedRebate & {
  text: string;
  msId?: number;
  isQuestion?: boolean;
  popoverText?: string;
  defaultPopoverText: string;
  status: IncentiveStatus;
  value?: number;
};

export const getDealerRebate = (
  msrp: number,
  currentSellingPrice: number,
): UIRebate => {
  const dealerDiscountValue = msrp - currentSellingPrice;
  const dealerDiscountText =
    dealerDiscountValue > 0
      ? `${BRAND_NAME} Negotiated Discount`
      : 'Market Adjustment';
  const popoverText =
    dealerDiscountValue > 0
      ? `This discount reflects ${BRAND_NAME}'s efforts to secure a better price for you through negotiations with the dealer, aiming to provide you with a more favorable deal on the vehicle.`
      : "This refers to an additional charge or adjustment set by the dealer, potentially impacting the vehicle's price beyond the manufacturer's suggested retail price (MSRP). Such adjustments can vary and may be influenced by market conditions, seasonal trends, or dealership policies.";
  return {
    id: DEALER_REBATE_ID,
    text: dealerDiscountText,
    defaultPopoverText: popoverText,
    status: IncentiveStatus.autoApplied,
    value: Math.round(dealerDiscountValue),
  };
};
export { getRebatesCategoriesFromAnswersUsingQuestions } from '../../shared/utils/functions';
