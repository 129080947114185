import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Popover from 'components/molecules/Popover';
import { StyledFontAwesomeIconMui5 } from 'components/atoms/FontAwesomeIcon';
import { faCoins } from '@fortawesome/free-solid-svg-icons/faCoins';
import { moneyFormat } from 'shared/utils/functions';
import React from 'react';
import DueBreakdown from 'containers/deal/components/DueBreakdown';
import { Row } from './types';
import { Loadable } from 'recoil';
import { GetProgramResponse } from 'shared/types/api/getProgram';
import { getDueAtSignin } from 'shared/utils/program';

type Props = {
  program: Loadable<GetProgramResponse>;
  item: Row;
  loading?: boolean;
};

export const Bid: React.FC<Props> = ({ program, loading, children }) => {
  if (loading) {
    return <CircularProgress size={20} />;
  }

  return (
    <>
      <StyledFontAwesomeIconMui5
        icon={faCoins}
        color='secondary'
        sx={(t) => ({ color: t.palette.secondary.main })}
        size='lg'
      />
      <Typography display='block' variant='body1' color='primary'>
        <b>{moneyFormat(Number(program.valueMaybe()?.Payment), true)}</b>{' '}
        <Typography
          component='span'
          variant='body2'
          display='inline'
          color='textSecondary'
        >
          {program.valueMaybe()?.FullDetails?.Lease?.IsOnePay
            ? 'one pay lease'
            : 'per month'}
        </Typography>
        {children}
      </Typography>
      {!program.valueMaybe()?.FullDetails?.Lease?.IsOnePay && (
        <Typography variant='body2' color='textSecondary' noWrap>
          (+
          <b>{moneyFormat(getDueAtSignin(program.valueMaybe()), true)}</b> due
          at signing)
          <Popover popoverProps={{ style: { verticalAlign: 'text-top' } }}>
            <DueBreakdown program={program} retractable={true} />
          </Popover>
        </Typography>
      )}
    </>
  );
};
