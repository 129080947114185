import React, { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { SnackbarProvider } from 'notistack';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { ThemeProvider } from '@mui/material/styles';
import { lightThemeMui5 } from '../theme';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import 'cross-fetch/polyfill';

import 'odometer/themes/odometer-theme-minimal.css';
import '../node_modules/react-image-gallery/styles/css/image-gallery.css';
import '../node_modules/react-datepicker/dist/react-datepicker.css';
import '../styles/app.css';

import { appName } from 'utils/seo';

// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import '@fortawesome/fontawesome-svg-core/styles.css';
// Prevent fontawesome from adding its CSS since we did it manually above:
import { RecoilRoot } from 'recoil';
import { TimeTick } from '../components/atoms/TimeTick';
import { PageTransition } from 'containers/App/PageTransition';
import {
  clientStorage,
  cookieStringAtom,
  getGoogleReviewsAtom,
  userAtom,
} from '../recoil/atoms';
import {
  auctionAtom,
  auctionBidsAtom,
  milesInputAtom,
  moInputSelector,
  scanModeInputAtom,
  userBidAtom,
  winningBidAtom,
} from 'containers/deal/recoil/atoms';
import { AVAILABLE_MILEAGES, SELECTABLE_TERMS } from 'shared/utils/constants';
import { filtersAtom } from 'services/filters/recoil';
import { useFBTrackPageView } from 'hooks/useFBTrackPageView';
import { useLogRocket } from 'hooks/useLogRocket';
import { useDatadogRum } from 'hooks/useDatadogRum';
import { useInitUser } from 'hooks/useInitUser';
import { useHotjarMobileExitIntent } from 'hooks/useHotjarMobileExitIntent';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { growthbook } from 'services/growthbook';
import ErrorBoundary from '../containers/error/ErrorBoundary';
import { DealTypeEnum } from 'shared/types/endpoints';
import { MSAPIScanMode } from 'shared/types/marketScan';
import { CssBaseline } from '@mui/material';
import { NextPage } from 'next';
import { EmotionCache } from '@emotion/cache';
import { createEmotionCache } from '../utils/createEmotionCache';
import { CacheProvider } from '@emotion/react';

export type MyAppProps = AppProps & {
  Component: NextPage;
  emotionCache?: EmotionCache;
};

const clientSideEmotionCache = createEmotionCache();

const MyApp: React.FC<MyAppProps> = ({
  emotionCache = clientSideEmotionCache,
  pageProps,
  Component,
  ...props
}) => {
  useFBTrackPageView();
  useLogRocket();
  useDatadogRum();
  useInitUser(pageProps.user);
  useHotjarMobileExitIntent();
  useFeatureFlag(pageProps.user);

  // useEffect(() => {
  //   // Remove the server-side injected CSS.
  //   const jssStyles = document.querySelector('#jss-server-side');
  //   if (jssStyles) {
  //     jssStyles.parentElement.removeChild(jssStyles);
  //   }
  // }, []);

  useEffect(() => {
    const unathorisedUserId = clientStorage.get('unathorised_user_id');
    if (!unathorisedUserId) {
      clientStorage.set('unathorised_user_id', uuid());
    }
  }, []);

  function initializeState({ set }) {
    const queryMiles = Number(props.router.query.miles);
    const queryTerm = String(props.router.query.term);

    if (pageProps.cookie) {
      set(cookieStringAtom, pageProps.cookie);
    }

    if (pageProps.winningBid) {
      set(winningBidAtom, pageProps.winningBid);
    }

    if (pageProps.reviews) {
      set(getGoogleReviewsAtom, pageProps.reviews);
    }

    if (pageProps.userBid) {
      set(userBidAtom, pageProps.userBid);
    }

    if (pageProps.auctionBids) {
      set(auctionBidsAtom, pageProps.auctionBids);
    }

    if (queryMiles && AVAILABLE_MILEAGES.includes(queryMiles)) {
      set(milesInputAtom, queryMiles);
    }

    if (queryTerm && SELECTABLE_TERMS.includes(queryTerm)) {
      set(moInputSelector, queryTerm);
    }

    if (pageProps.filtersAtomData) {
      set(filtersAtom, pageProps.filtersAtomData);
      const dealType = pageProps.filtersAtomData.dealType;
      if (dealType) {
        const scanMode =
          dealType === DealTypeEnum.FINANCE
            ? MSAPIScanMode.Retail
            : MSAPIScanMode.Lease;

        set(scanModeInputAtom, scanMode);
      }
    }

    if (pageProps.user) {
      set(userAtom, pageProps.user);
    }

    if (pageProps.auction) {
      set(auctionAtom, pageProps.auction);
    }
  }

  return (
    // FIXME: play with types for ssr styles cache
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore emotionCache is not a valid prop for CacheProvider
    <CacheProvider value={emotionCache}>
      <GrowthBookProvider growthbook={growthbook}>
        <Head>
          <meta
            name='viewport'
            content='minimum-scale=1, initial-scale=1, width=device-width'
          />
          <meta
            name='theme-color'
            content={lightThemeMui5.palette.primary.main}
          />
          <title>{appName}</title>
        </Head>
        <ThemeProvider theme={lightThemeMui5}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <RecoilRoot initializeState={initializeState}>
            <PageTransition />
            <TimeTick />
            <SnackbarProvider>
              <ErrorBoundary>
                <Component {...pageProps} />
              </ErrorBoundary>
            </SnackbarProvider>
          </RecoilRoot>
        </ThemeProvider>
      </GrowthBookProvider>
    </CacheProvider>
  );
};

export default MyApp;
