import {
  AuctionProgramSelectorType,
  auctionProgramsSelector,
  auctionRebatesSelector,
  userAuctionRebatesSelector,
  userRebatesNamesSelector,
} from 'recoil/selectors';
import { addDays } from 'date-fns';
import { GetRecoilValue, selector, selectorFamily } from 'recoil';
import {
  DueAtSigningType,
  GetProgramResponse,
} from 'shared/types/api/getProgram';
import {
  Auction,
  AuctionPresence,
  AuctionStatus,
  AuctionTypes,
  AuctionVisit,
  BidStatus,
  Offer,
  OfferStatus,
  Permission,
} from 'shared/types/models';
import {
  MIN_BID_STEP,
  MIN_ONE_PAY_BID_STEP,
  SearchTerm,
  TIME_TO_ACCEPT_DEAL_IN_DAYS,
} from 'shared/utils/constants';
import {
  auctionAtom,
  auctionBidsAtom,
  downPaymentInputSelectorFamily,
  dueAtSigningTypeInputSelectorFamily,
  isOwnersChoiceInputAtom,
  milesInputAtom,
  moInputSelector,
  msdInputAtom,
  onePayLeaseInputAtom,
  upfrontAcquisitionFeeInputAtom,
  userBidAtom,
  waiveTheAcquisitionFeeInputAtom,
  winningBidAtom,
  zeroDriveOffInputAtom,
  creditScoreInputAtom,
  scanModeInputAtom,
} from './atoms';
import {
  MSAPIRequestRebateCategory,
  MSISRebate,
} from 'shared/types/marketScan';
import { timeAtom, userAtom } from 'recoil/atoms';
import { convertRebateToAppliedRebate } from 'shared/incentives/convertRebateToAppliedRebate';
import {
  getBuyItNowAuctionSellingPrice,
  getUserCreditScore,
  hasPermission,
  isAvailableByState,
} from 'shared/utils/functions';
import Rest from '../../../services/rest';
import { AuctionFromSearch } from 'shared/types/api/getAuctions';
import {
  GetCityByZipcodeParams,
  GetCityByZipcodeResponse,
} from 'shared/types/api/getCityByZipcode';

// Derieved data from Core atoms
export const areAnyIncentivesApplicableSelector = selector<boolean>({
  key: 'ARE_ANY_INCENTIVES_APPLICABLE_SELECTOR',
  get: ({ get }) =>
    get(auctionAtom)?.rebates_data?.Rebates?.length > 0 || false,
});

export const dealIdSelector = selector<number>({
  key: 'DEAL_ID_SELECTOR',
  get: ({ get }) => get(auctionAtom)?.id,
});

type BidFormProgramsRequestParams = {
  currProgramId?: number;
  mo?: SearchTerm;
  salesPrice?: number;
  monthlyPayment?: number;
  selectedRebates?: MSISRebate[];
  rebatesCategories?: MSAPIRequestRebateCategory[];
  disableAutoRebates?: boolean;
  dueAtSigningType?: DueAtSigningType;
  downPayment?: number;
  upfrontAcquisitionFee?: boolean;
  zipcode?: string;
  programsCount?: number;
};

const getAuctionProgramsSelectorParams = (
  overwrite: BidFormProgramsRequestParams,
  get: GetRecoilValue,
): AuctionProgramSelectorType => {
  const auction = get(auctionAtom);
  const user = get(userAtom);

  const isOwnersChoice = get(isOwnersChoiceInputAtom);
  const downPayment =
    typeof overwrite.downPayment !== 'undefined'
      ? overwrite.downPayment
      : get(downPaymentInputSelectorFamily(auction.id));
  const miles = get(milesInputAtom);
  const mo = overwrite?.mo ?? get(moInputSelector);
  const msd = get(msdInputAtom);
  const onePayLease = get(onePayLeaseInputAtom);
  const upfrontAcquisitionFee =
    overwrite.upfrontAcquisitionFee !== undefined
      ? overwrite.upfrontAcquisitionFee
      : get(upfrontAcquisitionFeeInputAtom);
  const zeroDriveOff = get(zeroDriveOffInputAtom);
  const waiveTheAcquisitionFee = get(waiveTheAcquisitionFeeInputAtom);
  const creditScore = getUserCreditScore(user, get(creditScoreInputAtom));
  const scanMode = get(scanModeInputAtom);

  const userAuctionRebates = overwrite.selectedRebates
    ? overwrite.selectedRebates
    : get(userAuctionRebatesSelector(auction));

  const monthlyPayment = overwrite?.salesPrice
    ? null
    : overwrite?.monthlyPayment;

  const dueAtSigningType =
    overwrite.dueAtSigningType ||
    get(dueAtSigningTypeInputSelectorFamily(auction.id));
  const rebatesCategories = overwrite?.rebatesCategories;
  const disableAutoRebates = Boolean(overwrite?.disableAutoRebates);

  const result = {
    auction: {
      msrp: auction.msrp,
      invoicePrice: auction.invoice_price,
      offer: {
        mileage: auction.offer.mileage,
        zip: auction.offer.zip,
        vehicleId: auction.offer.vehicle_id,
      },
    },
    user,
    params: {
      programId: overwrite?.currProgramId,
      auctionId: auction.id,
      isOwnersChoice,
      downPayment,
      miles,
      mo,
      msd,
      onePayLease,
      upfrontAcquisitionFee: [
        DueAtSigningType.ZERO_SIGNING,
        DueAtSigningType.ZERO_SIGNING_DOWNPAYMENT_ONLY,
      ].includes(dueAtSigningType)
        ? false
        : upfrontAcquisitionFee,
      zeroDriveOff,
      waiveTheAcquisitionFee,
      monthlyPayment,
      salesPrice: overwrite?.salesPrice,
      rebates: userAuctionRebates.map(convertRebateToAppliedRebate),
      ...(rebatesCategories ? { rebatesCategories } : {}),
      disableAutoRebates,
      dueAtSigningType,
      creditScore,
      ...(overwrite?.zipcode ? { zipcode: overwrite?.zipcode } : {}),
      ...(overwrite?.programsCount
        ? { programsCount: overwrite?.programsCount }
        : {}),
      scanMode,
    },
  };

  return result;
};

const isGetBidFormProgramsRequestInvalid = (
  overwrite: BidFormProgramsRequestParams,
  get: GetRecoilValue,
) => {
  const auction = get(auctionAtom);

  const monthlyPayment = overwrite?.salesPrice
    ? null
    : overwrite?.monthlyPayment;

  const salesPrice = overwrite?.salesPrice;
  const dueAtSigningType =
    overwrite.dueAtSigningType ||
    get(dueAtSigningTypeInputSelectorFamily(auction.id));

  if ((!salesPrice && !monthlyPayment) || !auction || !dueAtSigningType) {
    return true;
  }

  return false;
};

export const bidFormProgramsSelector = selectorFamily<
  GetProgramResponse[],
  BidFormProgramsRequestParams
>({
  key: 'UI_PROGRAMS_SELECTOR',
  get:
    (overwrite?) =>
    async ({ get }) => {
      const isRequestInvalid = isGetBidFormProgramsRequestInvalid(
        overwrite,
        get,
      );

      if (isRequestInvalid) {
        return null;
      }
      const auctionPrograms = get(
        auctionProgramsSelector(
          getAuctionProgramsSelectorParams(overwrite, get),
        ),
      );

      return auctionPrograms;
    },
});

export const bidFormProgramSelector = selectorFamily<
  GetProgramResponse,
  BidFormProgramsRequestParams
>({
  key: 'UI_PROGRAM_SELECTOR',
  get:
    (overwrite?) =>
    async ({ get }) => {
      const isRequestInvalid = isGetBidFormProgramsRequestInvalid(
        overwrite,
        get,
      );

      if (isRequestInvalid) {
        return null;
      }

      const auctionPrograms = get(
        auctionProgramsSelector(
          getAuctionProgramsSelectorParams(overwrite, get),
        ),
      );

      const auctionProgram = auctionPrograms[0];

      if (!auctionProgram) {
        return null;
      }

      return auctionProgram;
    },
});

export const currentSellingPriceSelector = selector({
  key: 'CURRENT_SELLING_PRICE_SELECTOR',
  get: ({ get }) => {
    const winningBid = get(winningBidAtom);
    const auction = get(auctionAtom);

    if (!Number.isFinite(auction?.discount)) {
      return;
    }

    return (
      winningBid?.min_program_price ||
      (auction as AuctionFromSearch)?.current_selling_price ||
      auction?.selling_price
    );
  },
});

export const currentSellingPriceWithoutDiscountSelector = selector({
  key: 'CURRENT_SELLING_PRICE_WITHOUT_DISCOUNT_SELECTOR',
  get: ({ get }) => {
    const auction = get(auctionAtom);

    if (!auction) {
      return null;
    }

    return auction.msrp;
  },
});

export const buyItNowSellingPriceSelector = selector({
  key: 'BUY_IT_NOW_SELLING_PRICE_SELECTOR',
  get: ({ get }) => {
    const auction = get(auctionAtom);

    if (!auction) {
      return null;
    }

    if (!Number.isFinite(auction.buy_it_now_discount)) {
      return null;
    }

    return getBuyItNowAuctionSellingPrice(
      auction.msrp,
      auction.buy_it_now_discount,
    );
  },
});

export const buyItNowSellingPriceWithoutDiscountSelector = selector({
  key: 'BUY_IT_NOW_SELLING_PRICE_WITHOUT_DISCOUNT_SELECTOR',
  get: ({ get }) => {
    const auction = get(auctionAtom);

    if (!auction) {
      return null;
    }

    return auction.msrp;
  },
});

export const isUserConfirmationNeededSelector = selector({
  key: 'IS_CONFIRMATION_NEEDED',
  get: ({ get }) =>
    get(userBidAtom)?.status === BidStatus.UserConfirmationNeeded,
});

export const isWinningSelector = selector<boolean>({
  key: 'IS_WINNING_SELECTOR',
  get: ({ get }) => {
    const user = get(userAtom);
    if (!user) {
      return false;
    }
    const winningBid = get(winningBidAtom);
    const isUserConfirmationNeeded = get(isUserConfirmationNeededSelector);

    return winningBid?.user_id === user.id && !isUserConfirmationNeeded;
  },
});

export const isLosingSelector = selector<boolean>({
  key: 'IS_LOSING_SELECTOR',
  get: ({ get }) => {
    const userBid = get(userBidAtom);
    const isWinning = get(isWinningSelector);

    return Boolean(userBid) && !isWinning;
  },
});

export const isOfferRejectedSelector = selector<boolean>({
  key: 'IS_OFFER_REJECTED_SELECOTR',
  get: ({ get }) =>
    get(auctionAtom)?.offer.status === OfferStatus.DealerRejected,
});

export const isRejectedSelector = selector<boolean>({
  key: 'IS_REJECTED_SELECOTR',
  get: ({ get }) => get(userBidAtom)?.status === BidStatus.DealerRejected,
});

export const isCanceledSelector = selector<boolean>({
  key: 'IS_CANCELED_SELECTOR',
  get: ({ get }) => get(auctionAtom)?.status === AuctionStatus.Canceled,
});

export const isExpiredSelector = selector<boolean>({
  key: 'IS_EXPIRED_SELECTOR',
  get: ({ get }) => get(auctionAtom)?.status === AuctionStatus.Expired,
});

export const isCompletedSelector = selector<boolean>({
  key: 'IS_COMPLETED_SELECTOR',
  get: ({ get }) => {
    return (
      get(auctionAtom)?.status === AuctionStatus.Completed ||
      (get(timeLeftSelector) < 0 &&
        !get(isExpiredSelector) &&
        !get(isCanceledSelector))
    );
  },
});

export const isActiveSelector = selector<boolean>({
  key: 'IS_ACTIVE_SELECTOR',
  get: ({ get }) =>
    !(
      get(isCompletedSelector) ||
      get(isExpiredSelector) ||
      get(isCanceledSelector)
    ),
});

export const isClassicSelector = selector<boolean>({
  key: 'IS_CLASIC_SELECTOR',
  get: ({ get }) => get(auctionAtom)?.type === AuctionTypes.Classic,
});

export const isFutureSelector = selector<boolean>({
  key: 'IS_FUTURE_SELECTOR',
  get: ({ get }) =>
    new Date(get(auctionAtom)?.start_time).getTime() > new Date().getTime(),
});

export const isStateMatchSelector = selector<boolean>({
  key: 'IS_STATE_MATCH',
  get: ({ get }) => {
    const user = get(userAtom);
    if (!user) {
      return true;
    }
    const auction = get(auctionAtom);
    if (!auction) {
      return true;
    }

    return isAvailableByState(auction.offer.state, user.state);
  },
});

export const isCosignerNeededSelector = selector<boolean>({
  key: 'IS_COSIGNER_NEEDED',
  get: ({ get }) => get(userBidAtom)?.status === BidStatus.CosignerNeeded,
});

export const isDealerSelector = selector<boolean>({
  key: 'IS_DEALER_SELECTOR',
  get: ({ get }) => hasPermission(get(userAtom), Permission.dealer) || false,
});

export const isConsumerSelector = selector<boolean>({
  key: 'IS_CONSUMER_SELECTOR',
  get: ({ get }) =>
    get(userAtom) ? hasPermission(get(userAtom), Permission.user) : true,
});

export const isAdminSelector = selector<boolean>({
  key: 'IS_ADMIN_SELECTOR',
  get: ({ get }) => hasPermission(get(userAtom), Permission.admin),
});

export const isAuthorizedConsumerSelector = selector<boolean>({
  key: 'IS_AUTHORIZED_CONSUMER_SELECTOR',
  get: ({ get }) => {
    const isAuthorized = get(isAuthorizedSelector);
    const isConsumer = get(isConsumerSelector);
    return isAuthorized && isConsumer;
  },
});

export const isAuthorizedSelector = selector<boolean>({
  key: 'IS_AUTHORIZED_SELECTOR',
  get: ({ get }) => Boolean(get(userAtom)),
});

export const needAskForRebatesBeforeBidSelector = selector<boolean>({
  key: 'ASK_FOR_REBATES_BEFORE_BID',
  get: ({ get }) => {
    const isConsumer = get(isConsumerSelector);
    const userRebatesNames = get(userRebatesNamesSelector) || [];

    return isConsumer && userRebatesNames.length === 0;
  },
});

export const makeNameSelector = selector<string>({
  key: 'MAKE_NAME_SELECTOR',
  get: ({ get }) => get(auctionAtom)?.offer?.make?.name,
});

export const modelNameSelector = selector<string>({
  key: 'MODEL_NAME_SELECTOR',
  get: ({ get }) => get(auctionAtom)?.offer?.model?.name,
});

const timeLeftSelector = selector<number>({
  key: 'TIME_LEFT_SELECTOR',
  get: ({ get }) =>
    new Date(get(auctionAtom)?.end_time).getTime() - get(timeAtom),
});

export const timeToAcceptSelector = selector<number>({
  key: 'TIME_TO_ACCEPT_SELECTOR',
  get: ({ get }) => {
    const userBid = get(userBidAtom);

    return userBid
      ? addDays(
          new Date(userBid.status_updated_at),
          TIME_TO_ACCEPT_DEAL_IN_DAYS,
        ).getTime()
      : 0;
  },
});

export const bidStepSelector = selector<number>({
  key: 'bidStepSelector',
  get: ({ get }) => {
    const onePayLease = get(onePayLeaseInputAtom);

    return onePayLease ? MIN_ONE_PAY_BID_STEP : MIN_BID_STEP;
  },
});

export const nextBidStepSelector = selector<number>({
  key: 'nextMinBidValue',
  get: ({ get }) => {
    const bidStep = get(bidStepSelector);
    const bids = get(auctionBidsAtom);

    const isOpeningBid = bids.length === 0;

    const step = isOpeningBid ? 0 : bidStep;

    return step;
  },
});

/*
 * This selector is simillar to getAuctionAppliedRebates method and returning the same data.
 * But it does not require any arguments. Auction will be taken from the atom and program will be calculated on the fly.
 */
export const auctionAppliedRebatesSelector = selectorFamily<
  [MSISRebate[], MSISRebate[]],
  MSISRebate[]
>({
  key: 'AUCTION_APPLIED_REBATES_SELECTOR',
  get:
    (selectedRebates?: MSISRebate[]) =>
    ({ get }) => {
      const auction = get(auctionAtom);
      const auctionRebates = get(
        auctionRebatesSelector({
          auction,
        }),
      );
      const currentSellingPrice = get(currentSellingPriceSelector);

      const program = get(
        bidFormProgramSelector({
          salesPrice: currentSellingPrice,
          selectedRebates,
        }),
      );

      return [
        (
          program?.AppliedRebate.map((appliedRebate) =>
            auctionRebates.find((rebate) => appliedRebate.ID === rebate.ID),
          ) || []
        ).filter(Boolean),
        selectedRebates,
      ];
    },
});

const rest = new Rest();
export const getAuctionByVinSelector = selectorFamily<Auction, Offer['vin']>({
  key: 'auctionByVinSelector',
  get: (param) => async () => {
    return rest.getAuctionByVin({ vin: param });
  },
});

export const getAuctionVisitsSelector = selectorFamily<
  AuctionVisit[],
  Auction['id']
>({
  key: 'auctionVisitsSelector',
  get: (param) => async () => {
    return rest.getAuctionVisits(param);
  },
});

export const getAuctionPresenceSelector = selectorFamily<
  AuctionPresence[],
  Auction['id']
>({
  key: 'auctionPresenceSelector',
  get: (param) => async () => {
    return rest.getAuctionPresence(param);
  },
});

export const getCityByZipcodeSelector = selectorFamily<
  GetCityByZipcodeResponse,
  GetCityByZipcodeParams
>({
  key: 'cityByZipcodeSelector',
  get: (params) => async () => {
    return rest.getCityByZipcode(params);
  },
});

const isBuyItNowDisabledSelector = selector<boolean>({
  key: 'isBuyItNowDisabledSelector',
  get: ({ get }) => {
    const auction = get(auctionAtom);

    return !Number.isFinite(auction?.buy_it_now_discount);
  },
});

export const isBidDisabledSelector = selector<boolean>({
  key: 'isBidDisabledSelector',
  get: ({ get }) => {
    const auction = get(auctionAtom);

    return !Number.isFinite(auction?.discount);
  },
});

const isNextBidExceedsBuyItNowPriceSelector = selectorFamily<
  boolean,
  {
    programPrice?: number;
    buyItNowProgramPrice?: number;
  }
>({
  key: 'isNextBidExceedsBuyItNowPriceSelector',
  get:
    ({ programPrice, buyItNowProgramPrice }) =>
    ({ get }) => {
      const isBuyItNowDisabled = get(isBuyItNowDisabledSelector);

      if (isBuyItNowDisabled) {
        return false;
      }

      return buyItNowProgramPrice <= programPrice;
    },
});

export const isBetterToUseBuyItNowSelector = selectorFamily<
  boolean,
  {
    programPayment?: number;
    buyItNowProgramPayment?: number;
  }
>({
  key: 'isBetterToUseBuyItNowSelector',
  get:
    ({ programPayment, buyItNowProgramPayment }) =>
    ({ get }) => {
      const bidStep = get(bidStepSelector);
      if (!programPayment || !buyItNowProgramPayment) {
        return false;
      }
      return programPayment + bidStep >= buyItNowProgramPayment;
    },
});

export const isClassicBidFormVisibleSelector = selectorFamily<
  boolean,
  {
    programPayment: number;
    programPrice: number;
    buyItNowProgramPayment: number;
    buyItNowProgramPrice: number;
    buyItNowExpected: boolean;
  }
>({
  key: 'isClassicBidFormVisible',
  get:
    ({
      programPayment,
      programPrice,
      buyItNowProgramPayment,
      buyItNowProgramPrice,
      buyItNowExpected,
    }) =>
    ({ get }) => {
      const isClassic = get(isClassicSelector);
      const isCanceled = get(isCanceledSelector);
      const isCompleted = get(isCompletedSelector);
      const isStateMatch = get(isStateMatchSelector);
      const isWinning = get(isWinningSelector);
      const isFuture = get(isFutureSelector);
      const isBetterToUseBuyItNow = get(
        isBetterToUseBuyItNowSelector({
          programPayment,
          buyItNowProgramPayment,
        }),
      );
      const isNextBidExceedsBuyItNowPrice = get(
        isNextBidExceedsBuyItNowPriceSelector({
          programPrice,
          buyItNowProgramPrice,
        }),
      );

      if (isCanceled || isCompleted) {
        return false;
      }

      if (!isClassic && !isFuture) {
        return false;
      }

      if (isWinning) {
        return false;
      }

      if (!isStateMatch) {
        return false;
      }

      if (isBetterToUseBuyItNow) {
        return false;
      }

      if (isNextBidExceedsBuyItNowPrice && !isFuture) {
        return false;
      }

      if (!buyItNowExpected) {
        return true;
      }

      if (buyItNowExpected && (!buyItNowProgramPayment || !programPayment)) {
        return false;
      }

      return true;
    },
});

export const isBuyItNowFormVisibleSelector = selector<boolean>({
  key: 'isBuyItNowFormVisibleSelector',
  get: ({ get }) => {
    const isCanceled = get(isCanceledSelector);
    const isCompleted = get(isCompletedSelector);
    const isStateMatch = get(isStateMatchSelector);
    const isBuyItNowDisabled = get(isBuyItNowDisabledSelector);
    const isClassic = get(isClassicSelector);
    const isFuture = get(isFutureSelector);

    if (isBuyItNowDisabled && isClassic) {
      return false;
    }

    if (!isStateMatch) {
      return false;
    }

    if (isCanceled || isCompleted) {
      return false;
    }

    if (isFuture) {
      return false;
    }

    return true;
  },
});
