import { MSISRebate, MSISRecipientType } from '../types/marketScan';
import { GetProgramResponse } from '../types/api/getProgram';

type Props = {
  auctionRebates: MSISRebate[];
  program: GetProgramResponse;
};

/*
 * Returns the list of rebates that are applied to the auction including custom rebates.
 *
 * @param {Auction} auction - The auction to get applied rebates for.
 * @param {GetProgramResponse} program - The program to get applied rebates for.
 * @returns {MSISRebate[]} - The list of applied rebates.
 */
export const getAuctionAppliedRebates = ({
  auctionRebates,
  program,
}: Props): MSISRebate[] => {
  if (!auctionRebates || !program || !program.AppliedRebate) {
    return [];
  }

  return program.AppliedRebate.filter(
    (appliedRebate) => appliedRebate.Recipient !== MSISRecipientType.Dealer,
  )
    .map((appliedRebate) => {
      return auctionRebates.find((rebate) => rebate.ID === appliedRebate.ID);
    })
    .filter(Boolean);
};
