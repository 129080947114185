import { ParsedUrlQuery } from 'querystring';
import { Auction } from 'shared/types/models';
import { getCityByZip, getStateByZip } from 'shared/services/geo';
import { BRAND_NAME } from 'shared/utils/constants';

export const appName = BRAND_NAME;

export type SEO = {
  title: (...args: any) => string;
  description: (...args: any) => string;
  keywords: (...args: any) => string;
};

const keywords = [
  'Lease',
  'Best Lease Deals',
  'Cheap Lease Deals',
  'Hot Leasing Deals',
  'Cheap Leasing Deals',
  'Bid',
  'Incentives',
  'Rebates',
  'Premium Options',
  'Dealer Discounts',
  'Lease Calculator',
  'Transparent Payments',
  BRAND_NAME,
].join(', ');

export const SEO_HOME_PAGE = {
  title: (): string =>
    `Car Leasing & Financing Deals | Explore Options at ${BRAND_NAME}`,
  description: (): string =>
    `Discover unbeatable car leasing and financing deals on ${BRAND_NAME}. Explore offers tailored to your location, featuring exclusive rebates and premium options, all at the best prices.`,
  keywords: (): string => keywords,
};

function getSearchData(
  zipcode: string,
  query: ParsedUrlQuery,
): { vehicleName: string; featureName: string; locationName: string } {
  const { fullState: state } = getStateByZip(zipcode);
  const { year, make, model, trim, feature, body_style } = query;
  const makeName = make ? `${make} ` : '';
  const modelName = model ? `${model} ` : '';
  const trimName = trim ? `${trim} ` : '';
  const yearName = year ? `${year} ` : '';
  const bodyName = body_style ? `${body_style} ` : '';
  const carNameholder = make || model || trim || body_style ? '' : 'vehicle ';
  const vehicleName = `${yearName}${carNameholder}${makeName}${modelName}${trimName}${bodyName}`;

  const featureName = feature ? `with ${feature} ` : '';
  const stateName = state ? `in ${state} ` : '';
  const city = getCityByZip(zipcode);
  const cityName = city ? `in ${city} ` : '';
  const locationName =
    city && state ? `in ${state}, ${city} ` : `${stateName}${cityName}`;

  return {
    vehicleName,
    featureName,
    locationName,
  };
}

export const SEO_SEARCH_PAGE = {
  title: (zipcode: string, query: ParsedUrlQuery): string => {
    const { vehicleName, featureName, locationName } = getSearchData(
      zipcode,
      query,
    );

    return `Lease or Buy a ${vehicleName}${featureName}${locationName}| ${appName}`;
  },
  description: (zipcode: string, query: ParsedUrlQuery): string => {
    const { vehicleName, featureName, locationName } = getSearchData(
      zipcode,
      query,
    );

    return `Find top ${vehicleName}${featureName}${locationName} deals with flexible leasing and financing. Transparent payments, personalized incentives, and best price bids.`;
  },

  keywords: (zipcode: string, query: ParsedUrlQuery): string => {
    const { fullState: state } = getStateByZip(zipcode);
    const { year, make, model, trim, body_style } = query;
    const city = getCityByZip(zipcode);

    return [state, city, year, make, model, trim, body_style, keywords]
      .filter(Boolean)
      .join(', ');
  },
};

export const SEO_AUCTION_PAGE = {
  title: (auction: Auction): string =>
    `${auction.offer.make.name} ${auction.offer.model.name} ${
      auction.offer.style_display_name || ''
    } ${auction.offer.year}`,
  description: (auction: Auction): string =>
    `Lease or Finance ${auction.offer.model.name} at ${auction.offer.state}. ${BRAND_NAME} offers transparent leasing and financing options with incentives and rebates.`,
  keywords: (): string => keywords,
};

export const SEO_SIGN_UP_PAGE = {
  title: (): string => `Sign Up | ${appName}`,
  description: (): string =>
    `Sign up for ${BRAND_NAME} - the best local leasing and financing deals, calculate payments with incentives and rebates. Sign up now and start biding to get new car!`,
  keywords: (): string => keywords,
};

export const SEO_LOGIN_PAGE = {
  title: (): string => `Log In | ${appName}`,
  description: (): string =>
    `Log in to ${BRAND_NAME} - the best local leasing and financing deals, calculate payments with incentives and rebates. Sign in now and start biding to get new car!`,
  keywords: (): string => keywords,
};

export const SEO_PRIVACY_POLICY_PAGE = {
  title: (): string => `Privacy Policy | ${appName}`,
  description: (): string =>
    `${BRAND_NAME} is committed to protecting your privacy. Our privacy policy outlines the types of information we collect, how we use it, and how we keep it. Read our privacy policy now.`,
  keywords: (): string => keywords,
};

export const SEO_ABOUT_US_PAGE = {
  title: (): string => `About Us | ${appName}`,
  description: (): string =>
    `${BRAND_NAME} is an online car auction service created by a team of car enthusiasts passionate about offering both luxury car leasing and financing at competitive prices. Learn more about us today.`,
  keywords: (): string =>
    [
      BRAND_NAME,
      'car leasing companies near me',
      'budget car lease',
      'car leasing company',
    ].join(','),
};

export const SEO_404_PAGE = {
  title: (): string => `Page Not Found | 404`,
  description: (): string =>
    'The page you are looking for is not found. Please check the URL and try again.',
  keywords: (): string => `404, Page Not Found, ${BRAND_NAME}`,
};

export const SEO_500_PAGE = {
  title: (): string => `Internal Server Error | 500`,
  description: (): string =>
    'Internal Server Error. Please try again later or contact our support team.',
  keywords: (): string => `500, Internal Server Error, ${BRAND_NAME}`,
};
