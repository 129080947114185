import { ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { IncentiveStatus } from 'shared/incentives';
import { green, grey, yellow } from 'theme/colors';
import { moneyFormat } from 'utils/functions';
import { UIRebate } from '../../../services/rebates/functions';
import { styled as styledMui5 } from '@mui/material/styles';
import { useScreenSize } from 'hooks/useScreenSize';
import { CheckboxField } from './CheckboxField';
import { StatusField } from './StatusField';
import { Theme as ThemeMui5 } from '@mui/material/styles/createTheme';

const Row = styledMui5('div')(
  ({ theme }) => `
  padding: ${theme.spacing(1, 0)};
  margin-bottom: -1px;
  border-top: 1px solid ${grey[200]};
  border-bottom: 1px solid ${grey[200]};`,
);

const EmptyStatusText = styledMui5('span')`
  padding: 3px;`;

const generateItemStyles = (theme: ThemeMui5, item: UIRebate) => {
  if (item.value < 0) {
    return `color: ${theme.palette.secondary.main};`;
  }
  if (
    [
      IncentiveStatus.autoSelectedNonStackable,
      IncentiveStatus.nonStackable,
    ].includes(item.status)
  ) {
    return `text-decoration: line-through;`;
  }
  return '';
};

const Item = styledMui5('div')<{ item: UIRebate }>(
  ({ theme, item }) => `
  white-space: nowrap;
  text-align: right;
  ${generateItemStyles(theme, item)}`,
);

export type MultipleCheckboxSelectItem = {
  id: string;
  text: string;
  popoverText?: string;
};

export type OnSelectRebate = (param: {
  item: UIRebate;
  selected: boolean;
}) => void;

export type OnChangeRebates = (ids: string[]) => void;

type Props = {
  items: Array<UIRebate>;
  selectedItemsIds: Array<string>;
  onChange?: OnChangeRebates;
  onSelect?: OnSelectRebate;
};

const TypographyWrap = styledMui5(Typography)`
  &.MuiTypography-root {
    white-space: break-spaces;
  }`;

const SpanNoWrap = styledMui5('span')`
  white-space: nowrap;`;

type IncentiveStatusIconMap = {
  [key in IncentiveStatus]: IconDefinition;
};

const IncentiveStatusIcon: IncentiveStatusIconMap = {
  [IncentiveStatus.applied]: faCheckCircle,
  [IncentiveStatus.autoApplied]: faCheckCircle,
  [IncentiveStatus.autoSelectedApplied]: faCheckCircle,
  [IncentiveStatus.autoSelectedNonStackable]: faExclamationCircle,
  [IncentiveStatus.loading]: faCircle,
  [IncentiveStatus.notApplied]: faPlusCircle,
  [IncentiveStatus.nonStackable]: faExclamationCircle,
};

type IncentiveStatusColorMap = {
  [key in IncentiveStatus]: string;
};

const IncentiveStatusColor: IncentiveStatusColorMap = {
  [IncentiveStatus.applied]: green[600],
  [IncentiveStatus.autoApplied]: green[600],
  [IncentiveStatus.autoSelectedApplied]: green[600],
  [IncentiveStatus.autoSelectedNonStackable]: yellow[600],
  [IncentiveStatus.loading]: '#fff',
  [IncentiveStatus.notApplied]: grey[600],
  [IncentiveStatus.nonStackable]: yellow[600],
};

type IncentiveStatusTextMap = {
  [key in IncentiveStatus]: ReactElement;
};

const IncentiveStatusText: IncentiveStatusTextMap = {
  [IncentiveStatus.applied]: (
    <Typography variant='caption' display='block'>
      Applied
    </Typography>
  ),
  [IncentiveStatus.autoApplied]: (
    <Typography variant='caption' display='block'>
      Auto-Applied
    </Typography>
  ),
  [IncentiveStatus.autoSelectedApplied]: (
    <TypographyWrap variant='caption' display='block'>
      <SpanNoWrap>Auto-Selected</SpanNoWrap> Applied
    </TypographyWrap>
  ),
  [IncentiveStatus.autoSelectedNonStackable]: (
    <TypographyWrap variant='caption' display='block'>
      <SpanNoWrap>Auto-Selected</SpanNoWrap> Non-stackable
    </TypographyWrap>
  ),
  [IncentiveStatus.loading]: (
    <TypographyWrap variant='caption' display='block'>
      loading...
    </TypographyWrap>
  ),
  [IncentiveStatus.notApplied]: (
    <Typography variant='caption' display='block'>
      Available
    </Typography>
  ),
  [IncentiveStatus.nonStackable]: (
    <Typography variant='caption' display='block'>
      Non-stackable
    </Typography>
  ),
};

const isIncentiveApplied = (status: IncentiveStatus) => {
  return [IncentiveStatus.autoApplied, IncentiveStatus.applied].includes(
    status,
  );
};

const formatValue = ({ item }) => {
  const value =
    item.value >= 0
      ? moneyFormat(item.value)
      : `(${moneyFormat(Math.abs(item.value))})`;

  if (isIncentiveApplied(item.status)) {
    return <b>{value}</b>;
  }

  return value;
};

export const MultipleCheckboxSelect = ({
  items,
  onChange,
  onSelect,
  selectedItemsIds = [],
}: Props): JSX.Element => {
  const { isExtraSmallScreen } = useScreenSize();
  return (
    <FormGroup>
      {items.map((item) => (
        <Row key={item.id}>
          <Grid container spacing={1} alignItems='center'>
            <Grid item xs={8} sm={7} md={7} lg={7}>
              <CheckboxField
                item={item}
                selectedItemsIds={selectedItemsIds}
                onChange={onChange}
                onSelect={onSelect}
              />
            </Grid>
            <Grid item xs={2} sm={3} md={3} lg={3}>
              {isExtraSmallScreen &&
              [
                IncentiveStatus.loading,
                IncentiveStatus.notApplied,
                IncentiveStatus.autoApplied,
              ].includes(item.status) ? (
                <EmptyStatusText></EmptyStatusText>
              ) : (
                <StatusField
                  text={IncentiveStatusText[item.status]}
                  icon={IncentiveStatusIcon[item.status]}
                  color={IncentiveStatusColor[item.status]}
                  loading={item.status === IncentiveStatus.loading}
                  ariaDisabled={[
                    IncentiveStatus.autoApplied,
                    IncentiveStatus.autoSelectedApplied,
                    IncentiveStatus.autoSelectedNonStackable,
                  ].includes(item.status)}
                />
              )}
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <Item item={item}>{formatValue({ item })}</Item>
            </Grid>
          </Grid>
        </Row>
      ))}
    </FormGroup>
  );
};
