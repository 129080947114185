import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import { guaranteeDepositDialogAtom } from './recoil/atoms';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useScreenSize } from 'hooks/useScreenSize';
import createPaymentIntent from 'containers/deal/utils/createPaymentIntent';
import { userAtom } from 'recoil/atoms';
import {
  forceRefreshUserPaymentCapturableSelector,
  userWinningBids,
} from 'services/user/recoil';
import GuaranteePayment from './GuaranteePayment';
import { IconButton, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const StyledDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const GuaranteeDepositDialog: React.FC = () => {
  const [guaranteeDepositDialog, openGuaranteeDepositDialog] = useRecoilState(
    guaranteeDepositDialogAtom,
  );
  const user = useRecoilValue(userAtom);
  const winningBids = useRecoilValueLoadable(userWinningBids);
  const auctionId = winningBids.valueMaybe()?.[0]?.auction_id;
  const forceRefreshUserPaymentCapturable = useSetRecoilState(
    forceRefreshUserPaymentCapturableSelector,
  );

  const { isExtraSmallScreen } = useScreenSize();

  const handleGuaranteeDeposit = async () => {
    await createPaymentIntent(auctionId, user.default_payment_method_id);

    openGuaranteeDepositDialog(false);

    forceRefreshUserPaymentCapturable((prev) => prev + 1);
  };

  const handleDialogClose = () => {
    openGuaranteeDepositDialog(false);
  };

  if (!guaranteeDepositDialog || !user || !auctionId) {
    return null;
  }

  return (
    <Dialog
      open={guaranteeDepositDialog}
      onClose={handleDialogClose}
      keepMounted={false}
      fullScreen={isExtraSmallScreen}
    >
      <DialogTitle>
        <StyledDiv>
          <span>Guarantee Deposit</span>
          <IconButton edge='end' onClick={handleDialogClose} aria-label='close'>
            <CloseIcon />
          </IconButton>
        </StyledDiv>
      </DialogTitle>
      <DialogContent>
        <GuaranteePayment
          onCreditCardAdded={handleGuaranteeDeposit}
        ></GuaranteePayment>
      </DialogContent>
    </Dialog>
  );
};
