import React, { useState } from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import NextLinkMui5 from 'components/atoms/NextLink';
import { StyledFontAwesomeIconMui5 } from 'components/atoms/FontAwesomeIcon';
import { TopNavMenuItemProps } from './TopNavMenuItem';
import { UserInformation } from './UserInformation';
import { useRouter } from 'next/router';
import kebabCase from 'lodash/kebabCase';
import { useRecoilValue, useRecoilState } from 'recoil';
import { userAtom, isMobileNavOpenAtom } from '../../../recoil/atoms';
import { useTasks } from 'services/user/hooks/useTasks';
import DrawerMui5 from '@mui/material/Drawer';
import { styled as styledMui5, useTheme } from '@mui/material/styles';
import IconButtonMui5 from '@mui/material/IconButton';
import DividerMui5 from '@mui/material/Divider';
import ListMui5 from '@mui/material/List';
import ListItemTextMui5 from '@mui/material/ListItemText';
import CollapseMui5 from '@mui/material/Collapse';
import BadgeMui5 from '@mui/material/Badge';
import ListItemButtonMui5 from '@mui/material/ListItemButton';
import { SxProps } from '@mui/system';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore actually there are no problems here with theme type, but my IDE thinks otherwise
const DrawerHeaderMui5 = styledMui5('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  justifyContent: 'flex-end',
  ...theme.mixins.toolbar,
}));

const MenuSubItemMui5 = styledMui5(ListItemButtonMui5, { skipSx: false })(
  ({ theme }) => `
  &.MuiListItemButton-root {
    padding-left: ${theme.spacing(4)}!important;
  }`,
);

const listItemStyles: SxProps = {
  display: 'flex',
  width: '100%',
  position: 'relative',
  boxSizing: 'border-box',
  textAlign: 'left',
  alignItems: 'center',
  paddingTop: '8px',
  paddingBottom: '8px',
  justifyContent: 'flex-start',
  textDecoration: 'none',
};

const listItemGuttersStyles: SxProps = {
  paddingLeft: '16px',
  paddingRight: '16px',
};

type TopNavMobileProps = {
  links: TopNavMenuItemProps[];
  isOpen: boolean;
  onClose: () => void;
};

export const TopNavMobile: React.FC<TopNavMobileProps> = (props) => {
  const theme = useTheme();
  const router = useRouter();
  const pathname = router.pathname;
  const user = useRecoilValue(userAtom);
  const [isMobileNavOpen, setIsMobileNavOpen] =
    useRecoilState(isMobileNavOpenAtom);

  const [openedItems, setOpenedItems] = useState<Record<string, boolean>>({
    Profile: pathname.indexOf('/profile') !== -1,
  });

  const handleMenuItemClick = (event, menuItem: TopNavMenuItemProps) => {
    if (menuItem.menuItems?.length > 0) {
      event.preventDefault();
      setOpenedItems((prev) => ({
        ...prev,
        [menuItem.name]: !prev[menuItem.name],
      }));
    } else {
      setIsMobileNavOpen(!isMobileNavOpen);
      menuItem.onClick?.();
    }
  };

  const { requiredIncompletedTasksCount: tasksBadgeContent } = useTasks({});

  return (
    <DrawerMui5
      variant='temporary'
      anchor='left'
      open={props.isOpen}
      onClose={props.onClose}
      sx={{ zIndex: 10000 }}
    >
      <DrawerHeaderMui5>
        {user && <UserInformation />}
        <IconButtonMui5
          onClick={() => {
            props.onClose();
          }}
        >
          <StyledFontAwesomeIconMui5
            icon={theme.direction === 'ltr' ? faChevronLeft : faChevronRight}
            color={theme.palette.primary.dark}
            size='xs'
          />
        </IconButtonMui5>
      </DrawerHeaderMui5>
      <DividerMui5 />
      <ListMui5 component={'nav'}>
        {props.links.map((link) => (
          <React.Fragment key={link.name}>
            <ListItemButtonMui5
              sx={{
                '&.MuiListItemButton-root': {
                  ...listItemStyles,
                  ...listItemGuttersStyles,
                },
              }}
              selected={pathname === link.href}
              component={link.href ? NextLinkMui5 : 'button'}
              href={link.href}
              onClick={(e) => handleMenuItemClick(e, link)}
            >
              <ListItemTextMui5 primary={link.name} />
              {link.menuItems?.length > 0 && (
                <StyledFontAwesomeIconMui5
                  icon={openedItems[link.name] ? faChevronLeft : faChevronDown}
                  color={theme.palette.primary.dark}
                  sx={{ color: theme.palette.primary.dark }}
                />
              )}
            </ListItemButtonMui5>
            {link.menuItems?.length > 0 && (
              <CollapseMui5
                in={Boolean(openedItems[link.name])}
                timeout='auto'
                unmountOnExit
              >
                <ListMui5 component='div' disablePadding>
                  {link.menuItems.map((subItem) => (
                    <MenuSubItemMui5
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore some weird issue with component not recognised as a valid prop of styled component with this attribute
                      component={subItem.href ? NextLinkMui5 : 'button'}
                      key={subItem.name}
                      href={subItem.href}
                      sx={{
                        '&.MuiListItemButton-root': {
                          ...listItemStyles,
                          ...listItemGuttersStyles,
                        },
                      }}
                      selected={
                        router.asPath ===
                        `${link.href}/${kebabCase(subItem.name)}`
                      }
                      onClick={(e) => {
                        setIsMobileNavOpen(!isMobileNavOpen);
                        subItem.onClick(
                          e as unknown as React.MouseEvent<
                            HTMLButtonElement,
                            MouseEvent
                          >,
                        );
                      }}
                    >
                      <ListItemTextMui5 primary={subItem.name} />
                      {subItem.name === 'Tasks' && (
                        <BadgeMui5
                          overlap='rectangular'
                          badgeContent={tasksBadgeContent}
                          color='secondary'
                        />
                      )}
                    </MenuSubItemMui5>
                  ))}
                </ListMui5>
              </CollapseMui5>
            )}
          </React.Fragment>
        ))}
      </ListMui5>
    </DrawerMui5>
  );
};
