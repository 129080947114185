import BezierEasing from 'bezier-easing';
import {
  Auction,
  AuctionTypes,
  CosignerRequestStatus,
  Offer,
  Permission,
  User,
} from '../types/models';
import {
  AVAILABLE_MONTHLY_PAYMENTS,
  DAYS_FOR_AUCTION_FINALIZATION,
  DAYS_FOR_AUCTION_START,
  DEFAULT_CREDIT_SCORE,
  DUTCH_COUNTDOWN_SECONDS,
  LAST_DAY_TO_MAKE_DECISION,
  LEASEBANDIT_IMAGE_BUCKET,
  MAKES_ARRAY,
  MAX_CREDIT_SCORE,
  MIN_CREDIT_SCORE,
  MINIMAL_USER_AGE_IN_YEARS,
  NJ_STATES_AVAILABLE_TO_BID,
  PAYMENT_BY_MSRP_FINANCE_BEST_RATE,
  PAYMENT_BY_MSRP_LEASE_BEST_RATE,
  PHONE_MAPPINGS,
  QA_CONSUMER_EMAIL_REGEX,
  TIME_TO_CONFIRM_DEAL_IN_DAYS,
} from './constants';
import {
  addDays,
  addMonths,
  differenceInYears,
  endOfMonth,
  isPast,
  lastDayOfMonth,
  setDate,
  startOfDay,
  startOfMonth,
  subDays,
  subMinutes,
} from 'date-fns';
import { Makes } from '../config/makes';
import upperFirst from 'lodash/upperFirst';
import lowerCase from 'lodash/lowerCase';
import { DynamicFilterCategory } from '../types/api/getAuctions';
import { CREDIT_TIERS, CreditTier } from '../config';
import {
  MSAPIAppliedRebate,
  MSAPIRequestRebateCategory,
  MSISRebate,
  MSISRebateCategory,
} from '../types/marketScan';
import isEmpty from 'lodash/isEmpty';
import { RebateCategoryAnswer, RebateCategoryQuestion } from '../types/common';

type Cosigner = {
  request_status?: CosignerRequestStatus;
};

const getEncodeBuffer = (): Buffer => {
  const defaultCharSingleByte = '?';
  const windows1252chars =
    '€�‚ƒ„…†‡ˆ‰Š‹Œ�Ž��‘’“”•–—˜™š›œ�žŸ ¡¢£¤¥¦§¨©ª«¬­®¯°±²³´µ¶·¸¹º»¼½¾¿ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿ';

  let asciiString = '';
  for (let i = 0; i < 128; i++) {
    asciiString += String.fromCharCode(i);
  }

  const chars = asciiString + windows1252chars;

  const encodeBuffer = Buffer.alloc(65536, defaultCharSingleByte.charCodeAt(0));

  for (let i = 0; i < chars.length; i++) {
    encodeBuffer[chars.charCodeAt(i)] = i;
  }

  return encodeBuffer;
};

const encodeWindows1252Buffer = getEncodeBuffer();

export const convertWindows1252ToUtf8 = (str: string): string => {
  const buf = Buffer.alloc(str.length);
  for (let i = 0; i < str.length; i++) {
    buf[i] = encodeWindows1252Buffer[str.charCodeAt(i)];
  }

  return buf.toString().replace(/�\?/g, '”'); // hardfix MS data issue
};

export const moneyFormat = (val: number, round = false): string => {
  if (val !== 0 && !val) {
    val = 0;
  }

  return (round ? Math.round(val) : val)
    .toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })
    .replace('.00', ''); // cut .00 in numbers like 1.00
};

export const getMonthlyPaymentIndex = ({
  minMonthlyPayment,
  maxMonthlyPayment,
}: {
  minMonthlyPayment?: number;
  maxMonthlyPayment?: number;
}): number => {
  return AVAILABLE_MONTHLY_PAYMENTS.map((availableMonthlyPayment) =>
    JSON.stringify([availableMonthlyPayment.min, availableMonthlyPayment.max]),
  ).indexOf(JSON.stringify([minMonthlyPayment, maxMonthlyPayment]));
};

export const getNonPastDateObject = (values: {
  [key: string]: any;
}): { start_time?: Date } => {
  if (!values.hasOwnProperty('start_time')) {
    return {};
  }
  return values.start_time && isPast(values.start_time)
    ? { start_time: new Date() }
    : {};
};

export const getPriceReductionPercent = (
  total: number,
  current: number,
): number => {
  return ((total - current) * 100) / total;
};

export const getAuctionCurrentDiscount = (auction: Partial<Auction>) => {
  const currentSellingPrice = getAuctionCurrentSellingPrice(auction);

  const currentDiscount = getPriceReductionPercent(
    auction.msrp,
    currentSellingPrice,
  );

  return currentDiscount;
};

export const getBuyItNowAuctionSellingPrice = (
  msrp: number,
  buyItNowDiscount: number,
): number => {
  if (!Number.isFinite(buyItNowDiscount)) {
    return;
  }

  return msrp * ((100 - buyItNowDiscount) / 100);
};

export const getAuctionCurrentSellingPrice = (
  auction: Partial<Auction>,
  _currentTime?: Date,
): number => {
  if (auction.type === AuctionTypes.Classic) {
    return getClassicAuctionCurrentSellingPrice(auction);
  }

  if (auction.type === AuctionTypes.Dutch) {
    return getDutchAuctionSellingPrice(auction, _currentTime);
  }
};

export const isCreditScoreValid = (creditScore: number): boolean => {
  return (
    isFinite(creditScore) &&
    creditScore >= MIN_CREDIT_SCORE &&
    creditScore <= MAX_CREDIT_SCORE
  );
};

export const getClassicAuctionCurrentSellingPrice = (
  auction: Partial<Auction>,
): number => {
  const sellingPriceFromBid = auction?.bids?.[0]?.min_program?.Price;
  const originalSellingPrice = auction.selling_price;

  return sellingPriceFromBid || originalSellingPrice;
};

export const getDutchAuctionSellingPrice = (
  auction: Partial<Auction>,
  _currentTime?: Date, // override time for testing purposes
): number => {
  // easing functions accept the argument from 0 to 1 range
  // and return the value from 0 to 1 range according to bezier_params
  const linearBezieParams = [0, 0, 1, 1];
  const bezierParams = auction.bezier_params || linearBezieParams;

  const easing = BezierEasing(
    bezierParams[0],
    bezierParams[1],
    bezierParams[2],
    bezierParams[3],
  );

  let currentTime = _currentTime || new Date();
  const startTime = new Date(auction.start_time);
  const endTime = new Date(auction.end_time);
  if (currentTime.getTime() < startTime.getTime()) {
    currentTime = startTime;
  }

  currentTime.setSeconds(
    Math.floor(currentTime.getSeconds() / DUTCH_COUNTDOWN_SECONDS) *
      DUTCH_COUNTDOWN_SECONDS,
  );

  currentTime.setMilliseconds(0);

  const auctionDuration = endTime.getTime() - startTime.getTime();

  const sinceStartDuration = currentTime.getTime() - startTime.getTime();

  const priceDiff =
    (auction.start_selling_price || auction.msrp) - auction.selling_price;

  const discount =
    priceDiff *
    easing(Math.min(sinceStartDuration, auctionDuration) / auctionDuration);

  return auction.start_selling_price - discount;
};

/**
 * The function returns new date with timezone offset reverted
 * @param timezonedDate - date with timezone offset applied
 * @returns - date with timezone offset reverted
 */
export function revertTimeZone(timezonedDate: Date): Date {
  const date = new Date(timezonedDate);

  return subMinutes(date, date.getTimezoneOffset());
}

export const getNextMonthDealEdgeDate = (auctionEndDate: Date) => {
  const rawDate = addDays(
    addMonths(startOfDay(setDate(auctionEndDate, 1)), 1),
    LAST_DAY_TO_MAKE_DECISION - 1,
  );

  // revert timezone offset applied by startOfDay()
  return revertTimeZone(rawDate);
};

/**
 * The function returns the maximum edge date when a dealer should make a decision
 * It should not be later than last day of the month when auction ends
 * Example:
 * 1. Auction ends on 01-05-2021, deadline = 01-05-2021 00:00:00
 * 2. Auction ends on 30-05-2021, deadline = 31-05-2021 00:00:00
 * 3. Auction ends on 31-12-2021, deadline = 31-12-2021 00:00:00
 * @param auctionEndDate - auction end date
 * @returns The edge date when a dealer should make a decision for the auction
 */
export const getThisMonthDealEdgeDate = (auctionEndDate: Date): Date => {
  const rawDate = lastDayOfMonth(auctionEndDate);

  // revert timezone offset applied by lastDayOfMonth()
  return revertTimeZone(rawDate);
};

/**
 * The function returns the deadline date when a dealer should make a decision
 * It should not be later than auction.end_date + TIME_TO_CONFIRM_DEAL_IN_DAYS
 *                          AND
 *                          than the last day of auction.end_date month
 * Example:
 * 1. Auction ends on 19-05-2021, deadline = 24-05-2021
 * 2. Auction ends on 30-05-2021, deadline = 31-05-2021 00:00:00
 * 3. Auction ends on 31-12-2021, deadline = 31-12-2021 00:00:00
 * @param auctionEndTime
 */
export const getDealDeadlineDateToConfirm = (auctionEndTime: string): Date => {
  const endDate = new Date(auctionEndTime);
  const thisMonthEdgeDate = getThisMonthDealEdgeDate(endDate);

  return new Date(
    Math.min(
      addDays(endDate, TIME_TO_CONFIRM_DEAL_IN_DAYS).getTime(),
      thisMonthEdgeDate.getTime(),
    ),
  );
};

/**
 * The function returns the edge date for the auction to end
 * @param endTime - desired auction end time
 * @returns The edge date for the auction to end
 */
export function getAuctionEndTimeEdgeDate(endTime: Date): Date {
  const rawDate = subDays(endOfMonth(endTime), DAYS_FOR_AUCTION_FINALIZATION);

  // revert timezone offset applied by endOfMonth()
  return revertTimeZone(rawDate);
}

/**
 * The function returns the edge date for the auction to start
 * @param startTime - desired auction start time
 * @returns The edge date for the auction to start
 */
export function getAuctionStartTimeEdgeDate(startTime: Date): Date {
  const rawDate = addDays(startOfMonth(startTime), DAYS_FOR_AUCTION_START);

  // revert timezone offset applied by startOfMonth()
  return revertTimeZone(rawDate);
}

export const hasPermission = (user: User, permissionName: Permission) => {
  if (!permissionName) {
    return true;
  }

  if (!user || !user.permissions) {
    return false;
  }

  return user.permissions.some(({ name }) => name === permissionName);
};

export const isValidAge = (birthDate: Date): boolean => {
  return differenceInYears(new Date(), birthDate) >= MINIMAL_USER_AGE_IN_YEARS;
};

export const isValidDate = (date: string): boolean => {
  return !isNaN(Date.parse(date));
};

// Guard from computing too long strings
export const MAX_LEVENSHTEIN_DISTANCE = 100;

/** Fast Levenshtein Distance https://en.wikipedia.org/wiki/Levenshtein_distance
 * @param s First string.
 * @param t Second string.
 * @returns Distance between two strings.
 * */
export function levenshteinDistance(s: string, t: string): number {
  if (s === t) {
    return 0;
  }
  const n = s.length,
    m = t.length;
  if (n > MAX_LEVENSHTEIN_DISTANCE || m > MAX_LEVENSHTEIN_DISTANCE) {
    return MAX_LEVENSHTEIN_DISTANCE;
  }
  if (n === 0 || m === 0) {
    return n + m;
  }
  let x = 0,
    y,
    a,
    b,
    c,
    d,
    g,
    h;
  const p = new Uint16Array(n);
  const u = new Uint32Array(n);
  for (y = 0; y < n; ) {
    u[y] = s.charCodeAt(y);
    p[y] = ++y;
  }

  for (; x + 3 < m; x += 4) {
    const e1 = t.charCodeAt(x);
    const e2 = t.charCodeAt(x + 1);
    const e3 = t.charCodeAt(x + 2);
    const e4 = t.charCodeAt(x + 3);
    c = x;
    b = x + 1;
    d = x + 2;
    g = x + 3;
    h = x + 4;
    for (y = 0; y < n; y++) {
      a = p[y];
      if (a < c || b < c) {
        c = a > b ? b + 1 : a + 1;
      } else {
        if (e1 !== u[y]) {
          c++;
        }
      }

      if (c < b || d < b) {
        b = c > d ? d + 1 : c + 1;
      } else {
        if (e2 !== u[y]) {
          b++;
        }
      }

      if (b < d || g < d) {
        d = b > g ? g + 1 : b + 1;
      } else {
        if (e3 !== u[y]) {
          d++;
        }
      }

      if (d < g || h < g) {
        g = d > h ? h + 1 : d + 1;
      } else {
        if (e4 !== u[y]) {
          g++;
        }
      }
      p[y] = h = g;
      g = d;
      d = b;
      b = c;
      c = a;
    }
  }

  for (; x < m; ) {
    const e = t.charCodeAt(x);
    c = x;
    d = ++x;
    for (y = 0; y < n; y++) {
      a = p[y];
      if (a < c || d < c) {
        d = a > d ? d + 1 : a + 1;
      } else {
        if (e !== u[y]) {
          d = c + 1;
        } else {
          d = c;
        }
      }
      p[y] = d;
      c = a;
    }
    h = d;
  }

  return h;
}

// Guard from computing too many strings
export const LIMIT_GET_CLOSEST_STRING = 1000;

type GetClosestStringOptions = {
  caseSensitive?: boolean;
  normalizedStringsB?: string[];
};

function getGuardedByLengthStrings(
  strings: string[] | undefined,
  maxLenght: number,
): string[] {
  if (!strings?.length) {
    return strings;
  }

  return strings.length < maxLenght ? strings : strings.slice(0, maxLenght);
}

/**
 * @param stringA First string.
 * @param stringsB Array of strings.
 * @param options Options. caseSensitive by default.
 * @returns The closest string from given array to the first one by Levenshtein Distance
 */
export function getClosestString(
  stringA: string,
  stringsB: string[],
  options?: GetClosestStringOptions,
): { string: string; distance: number } {
  if (!stringA || !stringsB?.length) {
    return null;
  }

  const caseSensitive = options?.caseSensitive ?? true;
  const normalizedStringA = caseSensitive ? stringA : stringA.toLowerCase();
  const guardedStringsB = getGuardedByLengthStrings(
    stringsB,
    LIMIT_GET_CLOSEST_STRING,
  );

  const normalizedGuardedStringsB = caseSensitive
    ? guardedStringsB
    : getGuardedByLengthStrings(
        options?.normalizedStringsB,
        LIMIT_GET_CLOSEST_STRING,
      ) || guardedStringsB.map((stringB) => stringB.toLowerCase());

  let closestString = guardedStringsB[0];
  let closestDistance = levenshteinDistance(
    normalizedStringA,
    normalizedGuardedStringsB[0],
  );
  let i = 1;

  for (; i < normalizedGuardedStringsB.length && closestDistance > 0; i++) {
    const distance = levenshteinDistance(
      normalizedStringA,
      normalizedGuardedStringsB[i],
    );
    if (distance < closestDistance) {
      closestString = guardedStringsB[i];
      closestDistance = distance;
    }
  }

  return {
    string: closestString,
    distance: closestDistance,
  };
}

export const getUserCosigner = (user: User): Cosigner => {
  return user?.cosigners?.length === 1 ? user.cosigners[0] : undefined;
};

const NORMILIZED_MAKES_ARRAY = MAKES_ARRAY.map((make) => make.toLowerCase());

export function normalizeMakeName(rawMakeName: string): string {
  const closestMake = getClosestString(rawMakeName, MAKES_ARRAY, {
    caseSensitive: false,
    normalizedStringsB: NORMILIZED_MAKES_ARRAY,
  }).string;
  return Makes[closestMake];
}

export const convertPhoneNumberToCognitoFormat = (phone = ''): string => {
  const phoneDigitsOnly = phone.replace(/\D/g, '');
  const cognitoPhone = `+1${phoneDigitsOnly}`;
  return PHONE_MAPPINGS[cognitoPhone] || cognitoPhone;
};

/**
 * Default function to check if offer is available to bid for the user by his state.
 * @param offerState State of the offer.
 * @param userState State of the user.
 * @returns Whether user is available to bid.
 * */
function defaultIsAvailableByState(
  offerState: string,
  userState: string,
): boolean {
  return offerState === userState;
}

/**
 * Special function to check if user is availavle to bid for NJ offers.
 * @param userState State of the user.
 * @returns Whether user is available to bid.
 */
function isStateAvailableForNJ(userState: string): boolean {
  return NJ_STATES_AVAILABLE_TO_BID.includes(userState);
}

/**
 * Universal function to check if offer is available to bid for the user by his state.
 * @param offerState State of the offer.
 * @param userState State of the user.
 * @returns Whether the offer is available to bid for the user.
 * */
export function isAvailableByState(
  offerState: string,
  userState: string,
): boolean {
  switch (offerState) {
    case 'NJ':
      return isStateAvailableForNJ(userState);
    default:
      return defaultIsAvailableByState(offerState, userState);
  }
}

export const sentenceCase = (str: string) => upperFirst(lowerCase(str));

export const isQATestUser = (email) => QA_CONSUMER_EMAIL_REGEX.exec(email);

export function getCreditTierByScore(creditScore: number): CreditTier {
  return CREDIT_TIERS.find(
    (tier) => creditScore >= tier.score.min && creditScore <= tier.score.max,
  );
}

export function getCreditTierMaxScore(creditScore: number): number {
  const creditTier = getCreditTierByScore(creditScore);

  return creditTier.score.max;
}

export function getUserCreditScore(
  user: User | undefined,
  defaulValue = DEFAULT_CREDIT_SCORE,
): number {
  const cosigner = user?.cosigners?.[0];
  const creditScore =
    cosigner?.credit_score || user?.credit_score || defaulValue;

  return creditScore;
}

export function normalizeOfferOptionCategory(category: string) {
  switch (category) {
    case 'GENERIC EXTERIOR COLOR':
      return DynamicFilterCategory.ExteriorColor;
    case 'INTERIOR COLOR':
      return DynamicFilterCategory.InteriorColor;
    case 'WHEELS':
      return DynamicFilterCategory.WheelType;
    case 'GENERIC TRANSMISSION TYPE':
      return DynamicFilterCategory.Transmission;
    case 'DRIVETRAIN':
      return DynamicFilterCategory.Drivetrain;
    case 'BODY STYLE':
      return DynamicFilterCategory.BodyStyle;
    case 'FUEL TYPE':
      return DynamicFilterCategory.FuelType;
    default:
      return DynamicFilterCategory.Premium;
  }
}

export function fastRGBWhiteness(rgbHexCode: string) {
  const color = parseInt(rgbHexCode, 16);

  return (
    ((color >> 16) & 0xff) * 212.6 +
    ((color >> 8) & 0xff) * 715.2 +
    (color & 0xff) * 72.2
  );
}

export function getOfferImage({
  offer,
  defaultImage = '/img/placeholder-car.png',
  ignoreProcessedImage,
}: {
  offer: Offer;
  defaultImage?: string;
  ignoreProcessedImage?: boolean;
}): string {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Property image is set on query by literal on the getAuctions service (e.g. search page)
    offer.processed_image && !ignoreProcessedImage
      ? `${LEASEBANDIT_IMAGE_BUCKET}/${offer.processed_image}` // TODO change me
      : '' ||
          offer.image ||
          offer.images?.[0]?.['@href'] ||
          offer.stock_image ||
          defaultImage
  );
}

export const evaluateProgramZipcode = ({
  isDealer,
  userZipcode,
  dealerZipcode,
  filterZipcode,
}: {
  isDealer: boolean;
  userZipcode: string;
  dealerZipcode: string;
  filterZipcode: string;
}) => {
  if (userZipcode) {
    return userZipcode;
  }
  if (isDealer) {
    return dealerZipcode;
  }
  if (filterZipcode) {
    return filterZipcode;
  }
  return dealerZipcode;
};

export const getUniqueStringArray = (arr: string[]) => [...new Set(arr)];

export const isBestDeal = (
  monthlyPayment: number,
  msrp: number,
  isLease: boolean,
) => {
  const rate = isLease
    ? PAYMENT_BY_MSRP_LEASE_BEST_RATE
    : PAYMENT_BY_MSRP_FINANCE_BEST_RATE;

  return monthlyPayment / msrp < rate;
};

export const rebatesCategoriesQuestions: RebateCategoryQuestion[] = [
  {
    title: 'Are you a recent college graduate?',
    categories: [
      {
        categoryName: 'College Grad',
      },
    ],
  },
  {
    title: 'Are you a first responder?',
    categories: [
      {
        categoryName: 'First Responders',
      },
    ],
  },
  {
    title: 'Are you or an immediate family member active or retired military?',
    categories: [
      {
        categoryName: 'Military',
      },
    ],
  },
  {
    title: 'Are you a current Costco member?',
    categories: [
      {
        categoryName: 'Membership',
        subcategoriesNames: ['Costco'],
      },
    ],
  },
];

export const defaultRebatesCategoriesAnswers =
  rebatesCategoriesQuestions.map<RebateCategoryAnswer>(({ title }) => ({
    isApplied: false,
    title,
  }));
const getSubcategoriesIds = (
  category: { categoryName: string; subcategoriesNames?: string[] },
  exactCategory: MSISRebateCategory,
) =>
  category.subcategoriesNames
    ?.map((subcategoryName) => {
      const exactSubcategory = exactCategory?.Subcategory.find(
        (msSubcategory) => subcategoryName == msSubcategory.Name,
      );
      return exactSubcategory.ID;
    })
    .filter(isEmpty) || [];

const reduceRebatesCategories = (
  rebateCategoryQuestion: RebateCategoryQuestion,
  msRebatesCategories: MSISRebateCategory[],
  accumulator: MSAPIRequestRebateCategory[],
) =>
  rebateCategoryQuestion.categories.reduce((acc, category) => {
    const exactCategory = msRebatesCategories.find(
      (msRebateCategory) => msRebateCategory.Name == category.categoryName,
    );
    if (!exactCategory) {
      return acc;
    }
    const categoryItem: MSAPIRequestRebateCategory = {
      ID: exactCategory.ID,
    };
    const subcategoriesIds = getSubcategoriesIds(category, exactCategory);
    if (subcategoriesIds.length > 0) {
      categoryItem.Subcategory = subcategoriesIds;
    }
    acc.push(categoryItem);
    return acc;
  }, accumulator as MSAPIRequestRebateCategory[]);

export const getRebatesCategoriesFromAnswersUsingQuestions = (
  rebatesCategoriesQuestions: RebateCategoryQuestion[],
  rebatesCategoriesAnswers: RebateCategoryAnswer[],
  msRebatesCategories: MSISRebateCategory[],
) => {
  if (isEmpty(rebatesCategoriesAnswers)) {
    return [];
  }
  return rebatesCategoriesAnswers.reduce((acc, answer) => {
    const rebateCategoryQuestion = rebatesCategoriesQuestions.find(
      (question) => answer.title === question.title && answer.isApplied,
    );
    if (!rebateCategoryQuestion) {
      return acc;
    }
    return reduceRebatesCategories(
      rebateCategoryQuestion,
      msRebatesCategories,
      acc,
    );
  }, [] as MSAPIRequestRebateCategory[]);
};

export const convertRebateToAppliedRebate = (
  msRebate: MSISRebate,
): MSAPIAppliedRebate => {
  return {
    ID: msRebate.ID,
    IdentCode: msRebate.IdentCode,
    Name: msRebate.Name,
    Number: msRebate.Number,
    RebateType: msRebate.Type,
    Recipient: msRebate.ReceipientType,
    StopDate: msRebate.StopDate,
    Value: msRebate.Value?.Values?.[0]?.Value,
    IsCustom: msRebate.IsCustom,
  };
};
