import React, { useEffect, useState } from 'react';
import Rest from 'services/rest';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import PaymentCard from 'components/organisms/PaymentCard';
import { useRecoilState } from 'recoil';
import { userAtom } from 'recoil/atoms';
import { DESKTOP_CONTAINER_MAX_WIDTH } from './constants';
import { StyledButtonMui5 } from 'components/atoms/StyledButton';
import reject from 'lodash/reject';
import head from 'lodash/head';
import PaymentMethod from './PaymentMethod';

const WidthExpander = styled('div')({
  width: DESKTOP_CONTAINER_MAX_WIDTH,
});
const StyledAlert = styled(Alert)(({ theme }) => ({
  '&.MuiAlert-root': {
    marginBottom: theme.spacing(1),
  },
}));
const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  '&.MuiLinearProgress-root': {
    marginBottom: theme.spacing(1),
  },
}));
const StyledContainer = styled('div')({
  maxWidth: DESKTOP_CONTAINER_MAX_WIDTH,
});
const StyledButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(2),
}));
const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  '&.MuiListSubheader-root': {
    padding: 0,
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    fontSize: '16px',
  },
}));

const rest = new Rest();

type GuaranteePaymentProps = {
  onCreditCardAdded: () => void | Promise<void>;
};

const GuaranteePayment: React.FC<GuaranteePaymentProps> = ({
  onCreditCardAdded,
}): JSX.Element => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [addFormVisible, setAddFormVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useRecoilState(userAtom);
  const [error, setError] = useState('');
  const defaultPaymentMethodId = user.default_payment_method_id;

  const refreshPaymentMethodsWithAutoHold = () => {
    setLoading(true);
    setError('');
    rest
      .listUserPaymentMethods()
      .then(async (paymentMethodList) => {
        const paymentMethods = paymentMethodList?.data || [];
        setPaymentMethods(paymentMethods);

        if (paymentMethods.length === 0) {
          setAddFormVisible(true);
          return;
        }

        // auto-hold if payment method is added
        try {
          await onCreditCardAdded();
        } catch (e) {
          setError(e.message || e.errors?.[0]?.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const asyncRefreshPaymentMethodsWithAutoHold = async () => {
    await refreshPaymentMethodsWithAutoHold();
  };

  useEffect(refreshPaymentMethodsWithAutoHold, []);

  const onSubmit = async () => {
    const updatedUser = await rest.getUserProfile();
    setUser(updatedUser);
    setAddFormVisible(false);
    await asyncRefreshPaymentMethodsWithAutoHold();
  };

  const hideAddCardForm = () => {
    setAddFormVisible(false);
  };

  async function deletePaymentMethod(paymentMethodId) {
    try {
      setLoading(true);
      setError('');
      const { defaultPaymentMethodId } = await rest.removePaymentMethod({
        payment_method_id: paymentMethodId,
      });
      setPaymentMethods(reject(paymentMethods, { id: paymentMethodId }));
      setUser({
        ...user,
        default_payment_method_id: defaultPaymentMethodId,
      });

      if (!defaultPaymentMethodId) {
        setAddFormVisible(true);
      }
    } catch (e) {
      const error: { msg: string } = e && e.errors && head(e.errors);
      error && setError(error.msg);
    } finally {
      setLoading(false);
    }
  }

  async function setDefaultPaymentMethod(paymentMethodId) {
    try {
      setLoading(true);
      setError('');
      await rest.setDefaultPaymentMethod(paymentMethodId);
      setUser({ ...user, default_payment_method_id: paymentMethodId });
    } catch (e) {
      const error: { msg: string } = e && e.errors && head(e.errors);
      error && setError(error.msg);
    } finally {
      setLoading(false);
    }
  }

  const showCallToAction =
    (!loading &&
      paymentMethods.length === 0 &&
      !user.default_payment_method_id) ||
    error;

  return (
    <StyledContainer>
      <WidthExpander />
      {loading && <StyledLinearProgress />}
      {error && (
        <StyledAlert severity='error' variant='outlined'>
          {error}
        </StyledAlert>
      )}
      {showCallToAction && (
        <Typography variant='subtitle2' gutterBottom>
          Please add a valid credit card before you can guarantee your deal
        </Typography>
      )}

      {paymentMethods.length > 0 && (
        <List
          subheader={
            <>
              <StyledListSubheader>Payment cards</StyledListSubheader>
              <Divider />
            </>
          }
        >
          {paymentMethods.map((paymentMethod) => (
            <PaymentMethod
              key={paymentMethod.id}
              paymentMethod={paymentMethod}
              defaultPaymentMethodId={defaultPaymentMethodId}
              onDeletePaymentMethod={deletePaymentMethod}
              onSetDefaultPaymentMethod={setDefaultPaymentMethod}
            />
          ))}
        </List>
      )}
      {addFormVisible ? (
        <div className='js-guarantee-payment'>
          <PaymentCard
            user={user}
            skipText={paymentMethods.length > 0 ? 'Close' : ''}
            submitText='Add guarantee deposit'
            alignButtons='left'
            onSubmit={onSubmit}
            onSkip={paymentMethods.length > 0 ? hideAddCardForm : undefined}
          />
        </div>
      ) : (
        !loading && (
          <StyledButtonsContainer>
            <StyledButtonMui5
              color='primary'
              onClick={refreshPaymentMethodsWithAutoHold}
            >
              Add Guarantee Deposit
            </StyledButtonMui5>
            <StyledButtonMui5
              color='primary'
              variant='outlined'
              onClick={() => setAddFormVisible(true)}
            >
              Add {paymentMethods.length > 0 ? 'Another ' : ''}Card
            </StyledButtonMui5>
          </StyledButtonsContainer>
        )
      )}
    </StyledContainer>
  );
};

export default GuaranteePayment;
