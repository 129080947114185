import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';

const StyledLabel = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-root': {
    fontSize: theme.typography.fontSize,
    marginLeft: theme.spacing(1),
  },
}));

const ControlLabel: React.FC<TypographyProps> = ({ ...props }) => {
  return <StyledLabel {...props} />;
};

export default ControlLabel;
