import { MSISRebate } from '../types/marketScan';

const REBATE_PERCENT_VALUE_TYPE = 1;

/*
 * This function returns the value of the rebate passed as an argument.
 */
export const getRebateValue = (rebate: MSISRebate, msrp: number): number => {
  const originalValue = rebate.Value.Values?.[0]?.Value;

  if (!originalValue) {
    return 0;
  }

  if (rebate.ValueType === REBATE_PERCENT_VALUE_TYPE) {
    return (originalValue / 100) * msrp;
  }

  return originalValue;
};
