import React from 'react';
import { useRouter } from 'next/router';
import { ButtonProps as ButtonPropsMui5 } from '@mui/material/Button';

import NextLinkMui5 from 'components/atoms/NextLink';
import { StyledButtonMui5 } from 'components/atoms/StyledButton';
import { styled as styledMui5 } from '@mui/material/styles';
import { Theme as ThemeMui5 } from '@mui/material/styles/createTheme';

export type TopNavItemProps = ButtonPropsMui5 & {
  onClick?: () => void;
  type?: 'button' | 'link';
  isWhite?: boolean;
  pathname?: string;
};

const generateCommonStyles = (theme: ThemeMui5) => `
  white-space: nowrap;
  color: ${theme.palette.primary.main};`;

const generateLinkStyles = (
  theme: ThemeMui5,
  isWhite: boolean,
  isActive: boolean,
): string => {
  let styles = `
    ${generateCommonStyles(theme)}
    text-transform: none;
    padding: ${theme.spacing(2.5, 2)};
    font-weight: normal;
    border-radius: 0;`;
  if (isWhite) {
    styles += `
      color: ${theme.palette.common.white};
      margin-bottom: -2px;`;
  }
  if (isActive) {
    styles += `
      border-bottom: 2px solid ${theme.palette.secondary.main};`;
    if (!isWhite) {
      styles += `
        color: ${theme.palette.secondary.main};`;
    }
  }
  return `
  &.MuiButton-root {
    ${styles} 
  }`;
};

const generateButtonStyles = (theme: ThemeMui5, isWhite: boolean): string => {
  let styles = `
    ${generateCommonStyles(theme)}
    min-width: 120px;
    margin-left: ${theme.spacing(1)};`;
  if (isWhite) {
    styles += `
      color: ${theme.palette.common.white}!important;
      border-color: ${theme.palette.common.white};`;
  }
  return `
  &.MuiButton-root {
    ${styles} 
  }`;
};

const NavButtonMui5 = styledMui5(StyledButtonMui5, {
  skipSx: false,
  shouldForwardProp: (propName) => {
    return (
      propName !== 'isWhite' && propName !== 'isActive' && propName !== 'isLink'
    );
  },
})<{
  isLink: boolean;
  isActive: boolean;
  isWhite: boolean;
}>(({ theme, isLink, isWhite, isActive }) =>
  isLink
    ? generateLinkStyles(theme, isWhite, isActive)
    : generateButtonStyles(theme, isWhite),
);

export const TopNavItem: React.FC<TopNavItemProps> = ({
  isWhite,
  type = 'link',
  ...props
}) => {
  const router = useRouter();
  const isLink = type === 'link';
  const isActive =
    props.href === router.pathname || props.pathname === router.pathname;

  return (
    <NavButtonMui5
      isLink={isLink}
      isActive={isActive}
      isWhite={isWhite}
      component={isLink ? NextLinkMui5 : 'button'}
      {...props}
    />
  );
};
